import { Modal, Button } from 'react-bootstrap'
import ChooseAssetResource from '../AlertRules/ChooseAssetResource'
import { useState } from 'react'
import {
  ApiUserAssetsAddTransactionPostRequest,
  AssetTypeEnum,
  BasketResourceEnum,
  TransactionTypeEnum
} from '../../api-client/generated'

interface Props {
  isModalShown: boolean
  onAdd: (value: ApiUserAssetsAddTransactionPostRequest) => void
  onClose: () => void
}

const BasketAddTransactionModal = (props: Props) => {
  const [assetTypeSelected, setAssetTypeSelected] = useState<AssetTypeEnum | undefined>()
  const [resourceIdSelected, setResourceIdSelected] = useState<number>(0)
  const [transactionType, setTransactionType] = useState<string>()
  const [resourceAmount, setResourceAmount] = useState<number>(0)
  const [resourcePrice, setResourcePrice] = useState<number>(0)

  const customStyles = {
    margin: '1rem'
  }

  const closeModal = () => {
    props.onClose()
  }

  const addTransaction = () => {
    const param: ApiUserAssetsAddTransactionPostRequest = {
      transactionType:
        transactionType?.toUpperCase() === 'BUY'
          ? TransactionTypeEnum.Buy
          : TransactionTypeEnum.Sell,
      assetTypeSelected:
        assetTypeSelected === AssetTypeEnum.Stock
          ? BasketResourceEnum.Stocks
          : BasketResourceEnum.Currencies,
      resourceIdSelected,
      resourceAmount,
      resourcePrice
    }

    props.onAdd(param)

    props.onClose()
  }

  const onAssetTypeChanged = (selectedAssetTypeId: AssetTypeEnum) => {
    setAssetTypeSelected(selectedAssetTypeId)
  }

  return (
    <Modal
      show={props.isModalShown}
      onRequestClose={closeModal}
      style={customStyles}
      size={'lg'}
    >
      <Modal.Header>
        <h2>Add transaction</h2>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>
            Buy{' '}
            <input
              id="rbtnBuy"
              type="radio"
              name="transactionType"
              onChange={() => setTransactionType('buy')}
            />
          </label>
          <label>
            Sell{' '}
            <input
              id="rbtnSell"
              type="radio"
              name="transactionType"
              onChange={() => setTransactionType('sell')}
            />
          </label>
        </div>
        <ChooseAssetResource
          onAssetTypeChange={(p) => onAssetTypeChanged(p)}
          onResourceChange={(p) => setResourceIdSelected(p)}
        />
        <div>
          Amount
          <br />
          <input
            type="number"
            onChange={(p) => setResourceAmount(Number(p.target.value))}
          />
        </div>
        <div>
          Price
          <br />
          <input
            type="number"
            onChange={(p) => setResourcePrice(Number(p.target.value))}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Cancel</Button>
        <Button onClick={addTransaction}>Add</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BasketAddTransactionModal
