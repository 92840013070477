import { Action } from 'redux'

interface AuthUserState {
  token: string | null
}

const initialState: AuthUserState = {
  token: null
}

const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'

interface LoginAction extends Action<typeof LOGIN> {
  payload: string
}

interface LogoutAction extends Action<typeof LOGOUT> {
}

export const loginAction = (authToken: string): LoginAction => ({
  type: LOGIN,
  payload: authToken
})

export const logoutAction = (): LogoutAction => ({
  type: LOGOUT
})

const authUserReducer = (state: AuthUserState = initialState, action: LoginAction | LogoutAction) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload
      }
    case LOGOUT:
      return {
        ...state,
        token: null
      }
    default:
      return state
  }
}

export default authUserReducer
