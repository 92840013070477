/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetTypeEnum,
    AssetTypeEnumFromJSON,
    AssetTypeEnumFromJSONTyped,
    AssetTypeEnumToJSON,
} from './AssetTypeEnum';

/**
 * 
 * @export
 * @interface AssetItem
 */
export interface AssetItem {
    /**
     * 
     * @type {string}
     * @memberof AssetItem
     */
    readonly id?: string | null;
    /**
     * 
     * @type {AssetTypeEnum}
     * @memberof AssetItem
     */
    type?: AssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetItem
     */
    readonly typeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetItem
     */
    readonly name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AssetItem
     */
    readonly count?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetItem
     */
    readonly currentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetItem
     */
    readonly buyValue?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetItem
     */
    readonly totalValue?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetItem
     */
    readonly totalBuyValue?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetItem
     */
    readonly totalGainBrutto?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetItem
     */
    readonly totalGainNetto?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetItem
     */
    readonly fee?: number;
}

export function AssetItemFromJSON(json: any): AssetItem {
    return AssetItemFromJSONTyped(json, false);
}

export function AssetItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : AssetTypeEnumFromJSON(json['type']),
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'currentValue': !exists(json, 'currentValue') ? undefined : json['currentValue'],
        'buyValue': !exists(json, 'buyValue') ? undefined : json['buyValue'],
        'totalValue': !exists(json, 'totalValue') ? undefined : json['totalValue'],
        'totalBuyValue': !exists(json, 'totalBuyValue') ? undefined : json['totalBuyValue'],
        'totalGainBrutto': !exists(json, 'totalGainBrutto') ? undefined : json['totalGainBrutto'],
        'totalGainNetto': !exists(json, 'totalGainNetto') ? undefined : json['totalGainNetto'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
    };
}

export function AssetItemToJSON(value?: AssetItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AssetTypeEnumToJSON(value.type),
    };
}

