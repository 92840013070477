import { ColDef } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { stockApiFactory } from '../../../../api-client/factory'
import { StockMarketIndexList } from '../../../../api-client/generated'
import BaseListPage from '../../../Common/Base/BaseListPage/BaseListPage'
import { stockColumns } from './stockColumns'

const Stocks = () => {
  const navigate = useNavigate()

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)

  const [rowData, setRowData] = useState<StockMarketIndexList[]>([])
  const [columns, setColumns] = useState<ColDef[]>([])
  const [statusDate, setStatusDate] = useState<Date | undefined>()

  useEffect(() => {
    setIsDataLoading(true)

    stockApiFactory()
      .apiStockAllGet()
      .then((p) => {
        setRowData(p.items!)

        const colsDef = stockColumns(p.items![0], navigate)
        setColumns(colsDef)

        setStatusDate(p.lastQuotationDate)
      })
      .finally(() => setIsDataLoading(false))
  }, [])

  return (
    <BaseListPage
      title="Notowania giełdowe"
      statusDate={statusDate!}
      rows={rowData}
      columnsDef={columns}
      isLoadingData={isDataLoading}
    />
  )
}

export default Stocks
