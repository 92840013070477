/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockTradeGainQueryResponse
 */
export interface StockTradeGainQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTradeGainQueryResponse
     */
    totalNettoGain?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTradeGainQueryResponse
     */
    totalBruttoGain?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTradeGainQueryResponse
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTradeGainQueryResponse
     */
    commission?: number;
}

export function StockTradeGainQueryResponseFromJSON(json: any): StockTradeGainQueryResponse {
    return StockTradeGainQueryResponseFromJSONTyped(json, false);
}

export function StockTradeGainQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockTradeGainQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalNettoGain': !exists(json, 'totalNettoGain') ? undefined : json['totalNettoGain'],
        'totalBruttoGain': !exists(json, 'totalBruttoGain') ? undefined : json['totalBruttoGain'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'commission': !exists(json, 'commission') ? undefined : json['commission'],
    };
}

export function StockTradeGainQueryResponseToJSON(value?: StockTradeGainQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalNettoGain': value.totalNettoGain,
        'totalBruttoGain': value.totalBruttoGain,
        'tax': value.tax,
        'commission': value.commission,
    };
}

