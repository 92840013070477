/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneralImportStatuses,
    GeneralImportStatusesFromJSON,
    GeneralImportStatusesFromJSONTyped,
    GeneralImportStatusesToJSON,
} from './GeneralImportStatuses';
import {
    StackImportStatuses,
    StackImportStatusesFromJSON,
    StackImportStatusesFromJSONTyped,
    StackImportStatusesToJSON,
} from './StackImportStatuses';

/**
 * 
 * @export
 * @interface GetImportStatusesQueryResponse
 */
export interface GetImportStatusesQueryResponse {
    /**
     * 
     * @type {Array<StackImportStatuses>}
     * @memberof GetImportStatusesQueryResponse
     */
    stacksImportStatuses?: Array<StackImportStatuses> | null;
    /**
     * 
     * @type {Array<GeneralImportStatuses>}
     * @memberof GetImportStatusesQueryResponse
     */
    currencyImportStatuses?: Array<GeneralImportStatuses> | null;
    /**
     * 
     * @type {Array<GeneralImportStatuses>}
     * @memberof GetImportStatusesQueryResponse
     */
    stacksMarketIndexImportStatuses?: Array<GeneralImportStatuses> | null;
    /**
     * 
     * @type {Array<GeneralImportStatuses>}
     * @memberof GetImportStatusesQueryResponse
     */
    nobleMetalsImportStatuses?: Array<GeneralImportStatuses> | null;
}

export function GetImportStatusesQueryResponseFromJSON(json: any): GetImportStatusesQueryResponse {
    return GetImportStatusesQueryResponseFromJSONTyped(json, false);
}

export function GetImportStatusesQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetImportStatusesQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stacksImportStatuses': !exists(json, 'stacksImportStatuses') ? undefined : (json['stacksImportStatuses'] === null ? null : (json['stacksImportStatuses'] as Array<any>).map(StackImportStatusesFromJSON)),
        'currencyImportStatuses': !exists(json, 'currencyImportStatuses') ? undefined : (json['currencyImportStatuses'] === null ? null : (json['currencyImportStatuses'] as Array<any>).map(GeneralImportStatusesFromJSON)),
        'stacksMarketIndexImportStatuses': !exists(json, 'stacksMarketIndexImportStatuses') ? undefined : (json['stacksMarketIndexImportStatuses'] === null ? null : (json['stacksMarketIndexImportStatuses'] as Array<any>).map(GeneralImportStatusesFromJSON)),
        'nobleMetalsImportStatuses': !exists(json, 'nobleMetalsImportStatuses') ? undefined : (json['nobleMetalsImportStatuses'] === null ? null : (json['nobleMetalsImportStatuses'] as Array<any>).map(GeneralImportStatusesFromJSON)),
    };
}

export function GetImportStatusesQueryResponseToJSON(value?: GetImportStatusesQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stacksImportStatuses': value.stacksImportStatuses === undefined ? undefined : (value.stacksImportStatuses === null ? null : (value.stacksImportStatuses as Array<any>).map(StackImportStatusesToJSON)),
        'currencyImportStatuses': value.currencyImportStatuses === undefined ? undefined : (value.currencyImportStatuses === null ? null : (value.currencyImportStatuses as Array<any>).map(GeneralImportStatusesToJSON)),
        'stacksMarketIndexImportStatuses': value.stacksMarketIndexImportStatuses === undefined ? undefined : (value.stacksMarketIndexImportStatuses === null ? null : (value.stacksMarketIndexImportStatuses as Array<any>).map(GeneralImportStatusesToJSON)),
        'nobleMetalsImportStatuses': value.nobleMetalsImportStatuses === undefined ? undefined : (value.nobleMetalsImportStatuses === null ? null : (value.nobleMetalsImportStatuses as Array<any>).map(GeneralImportStatusesToJSON)),
    };
}

