import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { stockApiFactory } from '../../../../api-client/factory'
import { AgGridReact } from 'ag-grid-react'
import { CompanyDividendItem } from '../../../../api-client/generated'
import getPropertiesFromObject from '../../../../extensions/getPropertiesFromObject'
import { mapNamesToAgGridColumns } from '../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import { ColDef } from 'ag-grid-community'
import UrlNavigationService from '../../../../services/url-service'

const StockDetailsDividend = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [rows, setRows] = useState<CompanyDividendItem[]>([])
  const [colDefs, setColDefs] = useState<ColDef[]>([])
  const [companyName, setCompanyName] = useState<string>('')

  useEffect(() => {
    stockApiFactory()
      .apiStockCompanyIdDividendGet({ companyId: Number(id) })
      .then((response) => {
        setRows(response.items!)

        const firstItem = response.items![0]
        const names = getPropertiesFromObject(firstItem)

        const excludedColumns = ['id', 'company', 'companyId', 'created']
        const cols = mapNamesToAgGridColumns(names, excludedColumns)

        setColDefs(cols)

        setCompanyName(response.companyName!)
      })
  }, [])

  const goBack = () => {
    navigate(UrlNavigationService.stockDetails(Number(id)))
  }

  return (
    <div>
      <h4>Dividend for {companyName}</h4>
      <button onClick={goBack}>Wróć</button>

      <div
        className="ag-theme-alpine"
        style={{ width: '100%', height: '80vh' }}
      >
        <AgGridReact columnDefs={colDefs} rowData={rows} />
      </div>
    </div>
  )
}

export default StockDetailsDividend
