/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CompanyDividendStatusEnum {
    Unknown = 'Unknown',
    Proposed = 'Proposed',
    Paid = 'Paid'
}

export function CompanyDividendStatusEnumFromJSON(json: any): CompanyDividendStatusEnum {
    return CompanyDividendStatusEnumFromJSONTyped(json, false);
}

export function CompanyDividendStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDividendStatusEnum {
    return json as CompanyDividendStatusEnum;
}

export function CompanyDividendStatusEnumToJSON(value?: CompanyDividendStatusEnum | null): any {
    return value as any;
}

