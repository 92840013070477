/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuotesStats,
    QuotesStatsFromJSON,
    QuotesStatsFromJSONTyped,
    QuotesStatsToJSON,
} from './QuotesStats';
import {
    StockMarketIndexQuote,
    StockMarketIndexQuoteFromJSON,
    StockMarketIndexQuoteFromJSONTyped,
    StockMarketIndexQuoteToJSON,
} from './StockMarketIndexQuote';

/**
 * 
 * @export
 * @interface GetStockMarketIndexDetailsQueryDto
 */
export interface GetStockMarketIndexDetailsQueryDto {
    /**
     * 
     * @type {Array<StockMarketIndexQuote>}
     * @memberof GetStockMarketIndexDetailsQueryDto
     */
    quotes?: Array<StockMarketIndexQuote> | null;
    /**
     * 
     * @type {Date}
     * @memberof GetStockMarketIndexDetailsQueryDto
     */
    statusDate?: Date | null;
    /**
     * 
     * @type {QuotesStats}
     * @memberof GetStockMarketIndexDetailsQueryDto
     */
    queryStats?: QuotesStats;
}

export function GetStockMarketIndexDetailsQueryDtoFromJSON(json: any): GetStockMarketIndexDetailsQueryDto {
    return GetStockMarketIndexDetailsQueryDtoFromJSONTyped(json, false);
}

export function GetStockMarketIndexDetailsQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStockMarketIndexDetailsQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quotes': !exists(json, 'quotes') ? undefined : (json['quotes'] === null ? null : (json['quotes'] as Array<any>).map(StockMarketIndexQuoteFromJSON)),
        'statusDate': !exists(json, 'statusDate') ? undefined : (json['statusDate'] === null ? null : new Date(json['statusDate'])),
        'queryStats': !exists(json, 'queryStats') ? undefined : QuotesStatsFromJSON(json['queryStats']),
    };
}

export function GetStockMarketIndexDetailsQueryDtoToJSON(value?: GetStockMarketIndexDetailsQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quotes': value.quotes === undefined ? undefined : (value.quotes === null ? null : (value.quotes as Array<any>).map(StockMarketIndexQuoteToJSON)),
        'statusDate': value.statusDate === undefined ? undefined : (value.statusDate === null ? null : value.statusDate.toISOString()),
        'queryStats': QuotesStatsToJSON(value.queryStats),
    };
}

