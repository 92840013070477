/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import {
    DateTimeQuarterEnum,
    DateTimeQuarterEnumFromJSON,
    DateTimeQuarterEnumFromJSONTyped,
    DateTimeQuarterEnumToJSON,
} from './DateTimeQuarterEnum';

/**
 * 
 * @export
 * @interface CompanyFinancialData
 */
export interface CompanyFinancialData {
    /**
     * 
     * @type {number}
     * @memberof CompanyFinancialData
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyFinancialData
     */
    created?: Date;
    /**
     * 
     * @type {number}
     * @memberof CompanyFinancialData
     */
    year?: number;
    /**
     * 
     * @type {DateTimeQuarterEnum}
     * @memberof CompanyFinancialData
     */
    quarter?: DateTimeQuarterEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanyFinancialData
     */
    companyId?: number;
    /**
     * 
     * @type {Company}
     * @memberof CompanyFinancialData
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof CompanyFinancialData
     */
    stockCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyFinancialData
     */
    equity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyFinancialData
     */
    gainNetto?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyFinancialData
     */
    ebitda?: number;
}

export function CompanyFinancialDataFromJSON(json: any): CompanyFinancialData {
    return CompanyFinancialDataFromJSONTyped(json, false);
}

export function CompanyFinancialDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFinancialData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'year': !exists(json, 'year') ? undefined : json['year'],
        'quarter': !exists(json, 'quarter') ? undefined : DateTimeQuarterEnumFromJSON(json['quarter']),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'stockCount': !exists(json, 'stockCount') ? undefined : json['stockCount'],
        'equity': !exists(json, 'equity') ? undefined : json['equity'],
        'gainNetto': !exists(json, 'gainNetto') ? undefined : json['gainNetto'],
        'ebitda': !exists(json, 'ebitda') ? undefined : json['ebitda'],
    };
}

export function CompanyFinancialDataToJSON(value?: CompanyFinancialData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'year': value.year,
        'quarter': DateTimeQuarterEnumToJSON(value.quarter),
        'companyId': value.companyId,
        'company': CompanyToJSON(value.company),
        'stockCount': value.stockCount,
        'equity': value.equity,
        'gainNetto': value.gainNetto,
        'ebitda': value.ebitda,
    };
}

