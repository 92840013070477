/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockQuote
 */
export interface StockQuote {
    /**
     * 
     * @type {Date}
     * @memberof StockQuote
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof StockQuote
     */
    opening?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuote
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuote
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuote
     */
    closing?: number;
    /**
     * 
     * @type {number}
     * @memberof StockQuote
     */
    volume?: number;
}

export function StockQuoteFromJSON(json: any): StockQuote {
    return StockQuoteFromJSONTyped(json, false);
}

export function StockQuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockQuote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'opening': !exists(json, 'opening') ? undefined : json['opening'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'closing': !exists(json, 'closing') ? undefined : json['closing'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
    };
}

export function StockQuoteToJSON(value?: StockQuote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'opening': value.opening,
        'max': value.max,
        'min': value.min,
        'closing': value.closing,
        'volume': value.volume,
    };
}

