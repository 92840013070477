import { ColDef } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import {
  CompanyRateResult,
  GetTheMostRecommendedCompaniesQueryResponse
} from '../../../api-client/generated'
import BaseListPage from '../../Common/Base/BaseListPage/BaseListPage'
import { assistantApiFactory } from '../../../api-client/factory'
import AgGridCellTimestampedValue from '../../../extensions/AgGridExtensions/AgGridCells/AgGridCellTimestampedValue'
import { addLinkColumn } from '../../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'
import UrlNavigationService from '../../../services/url-service'

const Assistant = () => {
  const [rows, setRows] = useState<CompanyRateResult[]>([])
  const [cols, setCols] = useState<ColDef[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const columns = getColumns()
    setCols(columns)

    setIsLoading(true)
    assistantApiFactory()
      .apiAssistantGetTheMostRecommendedCompaniesGet()
      .then((response: GetTheMostRecommendedCompaniesQueryResponse) => {
        setRows(response.items!)
      })
      .finally(() => setIsLoading(false))
  }, [])

  const getColumns = () => {
    const getStockUrl = (p: any) => {
      const id = Number(p.data?.companyId)
      return UrlNavigationService.stockDetails(id)
    }

    const getLinkName = (p: any) => {
      return p?.data?.companyName
    }

    const columns = [
      addLinkColumn(
        'companyName',
        '',
        (p) => getStockUrl(p),
        true,
        (p) => getLinkName(p)
      ),
      {
        field: 'relevancy',
        sortable: true,
        valueGetter: (params: any) => {
          return params.data.relevancy?.total
        }
      },
      {
        field: 'currentQuotation',
        sortable: true,
        valueGetter: (params: any) => {
          return params.data.inputModel.lastQuotation?.value
        }
      },
      {
        field: 'priceBookValue',
        valueGetter: (params: any) => {
          return params.data.inputModel.priceBookValue?.value
        },
        autoHeight: true,
        cellRenderer: AgGridCellTimestampedValue,
        cellRendererParams: {
          getDataValue: (params: any) => params.data.inputModel.priceBookValue
        }
      },
      {
        field: 'priceEarningRating',
        valueGetter: (params: any) => {
          return params.data.inputModel.priceEarningRating?.value
        },
        autoHeight: true,
        cellRenderer: AgGridCellTimestampedValue,
        cellRendererParams: {
          getDataValue: (params: any) =>
            params.data.inputModel.priceEarningRating
        }
      },
      {
        field: 'rating',
        valueGetter: (params: any) => {
          return params.data.inputModel.rating?.value
        },
        autoHeight: true,
        cellRenderer: AgGridCellTimestampedValue,
        cellRendererParams: {
          getDataValue: (params: any) => params.data.inputModel.rating
        }
      },
      {
        field: 'recommendationCount',
        valueGetter: (params: any) => {
          return params.data.inputModel.recommendations?.length
        }
      },
      {
        field: 'dividentCount',
        valueGetter: (params: any) => {
          return params.data.inputModel.dividend?.yearWithContinuePaidDividends
        }
      }
    ]

    return columns
  }

  return isLoading
    ? <>Data is loading...</>
    : <>
        <BaseListPage
          title="Asystent"
          statusDate={new Date()}
          rows={rows}
          columnsDef={cols}
        />
      </>
}

export default Assistant
