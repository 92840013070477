import { combineReducers, createStore } from 'redux'
import authUserReducer from './reducers/auth-user-reducer'

const rootReducer = combineReducers({
  authUser: authUserReducer
})

export type RootState = ReturnType<typeof rootReducer>

const store = createStore(rootReducer)

export default store
