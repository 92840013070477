/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetCurrentCurrenciesQueryResponse,
    GetCurrentCurrenciesQueryResponseFromJSON,
    GetCurrentCurrenciesQueryResponseToJSON,
    GetCurrentCurrencyDetailsQueryResponse,
    GetCurrentCurrencyDetailsQueryResponseFromJSON,
    GetCurrentCurrencyDetailsQueryResponseToJSON,
} from '../models';

export interface ApiCurrencyCurrencyShortCodeGetRequest {
    shortCode: string;
    fromDate?: Date;
    toDate?: Date;
}

/**
 * 
 */
export class CurrencyApi extends runtime.BaseAPI {

    /**
     */
    async apiCurrencyAllGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Currency/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiCurrencyAllGet(initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiCurrencyAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCurrencyCurrencyShortCodeGetRaw(requestParameters: ApiCurrencyCurrencyShortCodeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCurrentCurrencyDetailsQueryResponse>> {
        if (requestParameters.shortCode === null || requestParameters.shortCode === undefined) {
            throw new runtime.RequiredError('shortCode','Required parameter requestParameters.shortCode was null or undefined when calling apiCurrencyCurrencyShortCodeGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString();
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Currency/currency/{shortCode}`.replace(`{${"shortCode"}}`, encodeURIComponent(String(requestParameters.shortCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentCurrencyDetailsQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCurrencyCurrencyShortCodeGet(requestParameters: ApiCurrencyCurrencyShortCodeGetRequest, initOverrides?: RequestInit): Promise<GetCurrentCurrencyDetailsQueryResponse> {
        const response = await this.apiCurrencyCurrencyShortCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCurrencyCurrentQuotesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCurrentCurrenciesQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Currency/current-quotes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentCurrenciesQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCurrencyCurrentQuotesGet(initOverrides?: RequestInit): Promise<GetCurrentCurrenciesQueryResponse> {
        const response = await this.apiCurrencyCurrentQuotesGetRaw(initOverrides);
        return await response.value();
    }

}
