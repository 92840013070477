import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './Menu.module.scss'
import sessionStorageService from '../services/session-storage-service'
import SearchBar from './SearchBar'

const Menu = () => {
  const singout = () => {
    sessionStorageService.removeAuthToken()

    window.location.replace('/login')
  }

  return (
    <Navbar bg="dark" variant="dark" className={styles.menu}>
      <Navbar.Brand as={Link} to="/" className="mar-l-1">Dashboard</Navbar.Brand>
      <Navbar.Collapse>
        <Nav>
          <Nav.Link as={Link} to="/basket"> Basket </Nav.Link>
          <Nav.Link as={Link} to="/assistant"> Assistant </Nav.Link>
          <Nav.Link as={Link} to="/market-indexes"> Indexies </Nav.Link>
          <NavDropdown title=" Stocks ">
            <NavDropdown.Item as={Link} to="/stocks"> Stocks </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/stocks/recommendations"> Recommendations </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/stocks/all-dividend"> Dividend </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/currencies"> Currencies </Nav.Link>
          <NavDropdown title=" Noble metals ">
            <NavDropdown.Item as={Link} to="/noble-metals/gold"> Gold </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title=" Analyzes ">
            <NavDropdown.Item as={Link} to="/calculations"> Buy/Sell Calculations </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/multi-chart"> Multi Chart </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/alerts"> Alerts </Nav.Link>
          <NavDropdown title=" Settings ">
            <NavDropdown.Item as={Link} to="/alert-rules"> Alert rules </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/alert-types"> Alert types </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/import-statuses"> Import statuses </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/data-sources"> Data sources </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Toggle />
      <Navbar.Collapse className={styles.alighRight}>
        <Nav className="me-auto">
        </Nav>
        <Nav.Item>
          <SearchBar />
        </Nav.Item>
        <Nav.Item>
          <a className={styles.logoutLink} href="#" onClick={singout}> Logout </a>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Menu
