/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NobleMetalEntity,
    NobleMetalEntityFromJSON,
    NobleMetalEntityFromJSONTyped,
    NobleMetalEntityToJSON,
} from './NobleMetalEntity';
import {
    QuotesStats,
    QuotesStatsFromJSON,
    QuotesStatsFromJSONTyped,
    QuotesStatsToJSON,
} from './QuotesStats';

/**
 * 
 * @export
 * @interface GetNobleMetalQuotationsQueryResponse
 */
export interface GetNobleMetalQuotationsQueryResponse {
    /**
     * 
     * @type {Array<NobleMetalEntity>}
     * @memberof GetNobleMetalQuotationsQueryResponse
     */
    items?: Array<NobleMetalEntity> | null;
    /**
     * 
     * @type {QuotesStats}
     * @memberof GetNobleMetalQuotationsQueryResponse
     */
    queryStats?: QuotesStats;
}

export function GetNobleMetalQuotationsQueryResponseFromJSON(json: any): GetNobleMetalQuotationsQueryResponse {
    return GetNobleMetalQuotationsQueryResponseFromJSONTyped(json, false);
}

export function GetNobleMetalQuotationsQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNobleMetalQuotationsQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(NobleMetalEntityFromJSON)),
        'queryStats': !exists(json, 'queryStats') ? undefined : QuotesStatsFromJSON(json['queryStats']),
    };
}

export function GetNobleMetalQuotationsQueryResponseToJSON(value?: GetNobleMetalQuotationsQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(NobleMetalEntityToJSON)),
        'queryStats': QuotesStatsToJSON(value.queryStats),
    };
}

