import { FC, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { accountApiFactory, homeApiFactory } from '../../api-client/factory'
import sessionStorageService from '../../services/session-storage-service'
import { loginAction } from '../../redux/reducers/auth-user-reducer'
import { useNavigate } from 'react-router-dom'
import appNavigate from '../../services/app-navigation-service'
import UrlNavigationService from '../../services/url-service'
import { showErrorToastr } from '../../services/toastr-notification-service'
import './LoginPage.scss'

const LoginPage: FC = () => {
  const [login, setLogin] = useState<string>()
  const [password, setPassword] = useState<string>()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    homeApiFactory().apiHomeStatusGet()
      .catch(() => {
        showErrorToastr('API not working')
      })
  }, [])

  const handleLoginChange = (event: any) => {
    setLogin(event.target.value)
  }

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value)
  }

  const handleLogin = () => {
    accountApiFactory().apiAccountAuthPost({
      loginCommand: {
        login,
        password
      }
    })
      .then((response) => {
        sessionStorageService.saveAuthToken(response)

        const url = UrlNavigationService.dashboardUrl()

        appNavigate(url, navigate)

        dispatch(loginAction(response))
      })
      .catch(() => {
        showErrorToastr('Logowanie się nie powiodło')
      })
  }

  return (
    <div className='login-page'>
      <div>
        <label>Login</label>
        <input onChange={handleLoginChange} />
      </div>
      <div>
        <label>Password</label>
        <input type='password' onChange={handlePasswordChange} />
      </div>
      <div>
        <Button onClick={handleLogin}>Login</Button>
      </div>
    </div>
  )
}

export default LoginPage
