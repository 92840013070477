/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetItem,
    AssetItemFromJSON,
    AssetItemFromJSONTyped,
    AssetItemToJSON,
} from './AssetItem';

/**
 * 
 * @export
 * @interface GetMyAssetsQueryResponse
 */
export interface GetMyAssetsQueryResponse {
    /**
     * 
     * @type {Array<AssetItem>}
     * @memberof GetMyAssetsQueryResponse
     */
    assets?: Array<AssetItem> | null;
    /**
     * 
     * @type {Date}
     * @memberof GetMyAssetsQueryResponse
     */
    quotationDate?: Date | null;
}

export function GetMyAssetsQueryResponseFromJSON(json: any): GetMyAssetsQueryResponse {
    return GetMyAssetsQueryResponseFromJSONTyped(json, false);
}

export function GetMyAssetsQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMyAssetsQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': !exists(json, 'assets') ? undefined : (json['assets'] === null ? null : (json['assets'] as Array<any>).map(AssetItemFromJSON)),
        'quotationDate': !exists(json, 'quotationDate') ? undefined : (json['quotationDate'] === null ? null : new Date(json['quotationDate'])),
    };
}

export function GetMyAssetsQueryResponseToJSON(value?: GetMyAssetsQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': value.assets === undefined ? undefined : (value.assets === null ? null : (value.assets as Array<any>).map(AssetItemToJSON)),
        'quotationDate': value.quotationDate === undefined ? undefined : (value.quotationDate === null ? null : value.quotationDate.toISOString()),
    };
}

