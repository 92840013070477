import AppRouting from './App/AppRouting'
import Menu from './Menu'
import './Layout.scss'

const Layout = () => {
  return <>
    <nav>
      <Menu/>
    </nav>
    <main>
      <AppRouting/>
    </main>
  </>
}

export default Layout
