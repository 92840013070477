import { ICellRendererParams } from 'ag-grid-community'

interface Props extends ICellRendererParams {
  getUrl: (param: any) => string
  linkName: string
  getLinkName?: (param: any) => string
}

const AgGridCellLink = (props: Props) => {
  if (!!props.getLinkName) {
    return <a href={props.getUrl(props)}>{props.getLinkName(props)}</a>
  }
  return <a href={props.getUrl(props)}>{props.linkName}</a>
}

export default AgGridCellLink
