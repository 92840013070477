import { AgGridReact } from 'ag-grid-react'
import { importStatusesApiFactory } from '../../api-client/factory'
import { useEffect, useState } from 'react'
import {
  AssetTypeEnum,
  GeneralImportStatuses,
  GetImportStatusesQueryResponse,
  StackImportStatuses
} from '../../api-client/generated'
import { ColDef } from 'ag-grid-community'
import { addLinkColumn, addTextWithButtonColumn } from '../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'
import {
  showSuccessToastr,
  showErrorToastr
} from '../../services/toastr-notification-service'
import { formatDate } from '../../services/culture-service'
import './ImportStatuses.scss'
import UrlNavigationService from '../../services/url-service'

const ImportStatuses = () => {
  const [stockRows, setStockRows] = useState<StackImportStatuses[]>([])
  const [stockColDefs, setstockColDefs] = useState<ColDef[]>([])

  const [currencyRows, setCurrencyRows] = useState<GeneralImportStatuses[]>([])
  const [marketStatusRows, setMarketStatusRows] = useState<
    GeneralImportStatuses[]
  >([])
  const [nobleMetalsStatusDate, setNobleMetalsStatusDate] = useState<Date>()

  // todo: link z nazwa
  const currencyCols: ColDef[] = [
    addLinkColumn('name', '', (p) => getCurrencyUrl(p), true, (p) => getLinkName(p)),
    { field: 'name' },
    { field: 'lastQuotationDate' }
  ]
  // todo: link z nazwa
  const marketStatusCols: ColDef[] = [
    addLinkColumn('name', '', (p) => getMarketIndexkUrl(p), true, (p) => getLinkName(p)),
    { field: 'name' },
    { field: 'lastQuotationDate' }
  ]

  const getMarketIndexkUrl = (p: any) => {
    return UrlNavigationService.marketIndexDetails(p.data.name)
  }

  const getCurrencyUrl = (p: any) => {
    const id = Number(p.data.id)

    return UrlNavigationService.currencyDetails(id)
  }

  const getStockUrl = (p: any) => {
    const id = Number(p.data.id)
    return UrlNavigationService.stockDetails(id)
  }

  const getLinkName = (p: any) => {
    return p?.data?.name
  }

  useEffect(() => {
    importStatusesApiFactory()
      .apiImportsGet()
      .then((resp: GetImportStatusesQueryResponse) => {
        const col = [
          addLinkColumn('name', '', (p) => getStockUrl(p), true, (p) => getLinkName(p)),
          {
            field: 'companyShortCode',
            sortable: true,
            filter: 'agTextColumnFilter'
          },
          addTextWithButtonColumn(
            'lastQuotationDate',
            'companyShortCode',
            runStockRefreshData
          ),
          addTextWithButtonColumn(
            'lastFinancialDataImportDate',
            'companyShortCode',
            runStockRefreshData
          ),
          addTextWithButtonColumn(
            'lastIndicatorImportDate',
            'companyShortCode',
            runStockRefreshData
          ),
          addTextWithButtonColumn(
            'lastDividendImportDate',
            'companyShortCode',
            runStockRefreshData
          )
        ]

        setCurrencyRows(resp.currencyImportStatuses!)
        setMarketStatusRows(resp.stacksMarketIndexImportStatuses!)

        setNobleMetalsStatusDate(
          resp!.nobleMetalsImportStatuses![0].lastQuotationDate!
        )

        setstockColDefs(col)
        setStockRows(resp.stacksImportStatuses!)
      })
  }, [])

  const runStockRefreshData = (id: string, propertyName: string) => {
    const param = {
      assetTypeEnum: AssetTypeEnum.Stock,
      id,
      propertyToRefresh: propertyName
    }

    importStatusesApiFactory()
      .apiImportsRunPost(param)
      .then(() => {
        showSuccessToastr('Imported successfully')

        // todo: info o sukcesie, ale brak danych
        // showInfoToastr
      })
      .catch(() => {
        showErrorToastr(`Cannot import ${propertyName} for ${id}`)
      })
  }

  const runCurrencyRefreshData = () => {
    importStatusesApiFactory()
      .apiImportsRunPost({
        assetTypeEnum: AssetTypeEnum.Currency
      })
      .then(() => {
        showSuccessToastr('Imported successfully')

        // showInfoToastr
      })
      .catch(() => {
        showErrorToastr('Cannot import currences quotations')
      })
  }

  const runMarketIndexesRefreshData = () => {
    importStatusesApiFactory()
      .apiImportsRunPost({
        assetTypeEnum: AssetTypeEnum.MarketIndex
      })
      .then(() => {
        showSuccessToastr('Imported successfully')

        // showInfoToastr
      })
      .catch(() => {
        showErrorToastr('Cannot import market index quotations')
      })
  }

  const runNobleMetalsRefreshData = () => {
    importStatusesApiFactory()
      .apiImportsRunPost({
        assetTypeEnum: AssetTypeEnum.NobleMetal
      })
      .then(() => {
        showSuccessToastr('Imported successfully')
      })
      .catch(() => {
        showErrorToastr('Cannot import noble metals quotations')
      })
  }

  return (
    <>
      <h1>Import statuses</h1>
      <div className="container">
        <div className="box">
          <h4>Currency</h4>
          <button onClick={runCurrencyRefreshData}>
            Refresh Currency data
          </button>
          <br />
          <div
            className="ag-theme-alpine"
            style={{ width: 500, height: 250 }}
          >
            <AgGridReact columnDefs={currencyCols} rowData={currencyRows} />
          </div>
        </div>
        <div className="box">
          <h4>Market Indexes</h4>
          <button onClick={runMarketIndexesRefreshData}>
            Refresh Market Index data
          </button>
          <br />
          <div
            className="ag-theme-alpine"
            style={{ width: 500, height: 250 }}
          >
            <AgGridReact
              columnDefs={marketStatusCols}
              rowData={marketStatusRows}
            />
          </div>
        </div>
        <div className="box">
          <h4>Noble metals</h4>
          <button onClick={runNobleMetalsRefreshData}>
            Refresh Noble Metals data
          </button>
          <br />
          Status data: {formatDate(nobleMetalsStatusDate)}
        </div>
      </div>
      <hr />
      <div>
        <h4>Stocks</h4>
        <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
          <AgGridReact columnDefs={stockColDefs} rowData={stockRows} />
        </div>
      </div>
    </>
  )
}

export default ImportStatuses
