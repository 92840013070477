/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDataSourcesQueryResponseClientInfo,
    GetDataSourcesQueryResponseClientInfoFromJSON,
    GetDataSourcesQueryResponseClientInfoFromJSONTyped,
    GetDataSourcesQueryResponseClientInfoToJSON,
} from './GetDataSourcesQueryResponseClientInfo';

/**
 * 
 * @export
 * @interface GetDataSourcesQueryResponse
 */
export interface GetDataSourcesQueryResponse {
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    stockMarketIndexClient?: GetDataSourcesQueryResponseClientInfo;
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    stockMarketIndexComponentClient?: GetDataSourcesQueryResponseClientInfo;
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    currencyQuotesClient?: GetDataSourcesQueryResponseClientInfo;
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    nobleMetalClient?: GetDataSourcesQueryResponseClientInfo;
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    stockRecommendationProvider?: GetDataSourcesQueryResponseClientInfo;
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    stockIndicatorProvider?: GetDataSourcesQueryResponseClientInfo;
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    companyFinancialDataProvider?: GetDataSourcesQueryResponseClientInfo;
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    stockDividendProvider?: GetDataSourcesQueryResponseClientInfo;
    /**
     * 
     * @type {GetDataSourcesQueryResponseClientInfo}
     * @memberof GetDataSourcesQueryResponse
     */
    stackQuotesClient?: GetDataSourcesQueryResponseClientInfo;
}

export function GetDataSourcesQueryResponseFromJSON(json: any): GetDataSourcesQueryResponse {
    return GetDataSourcesQueryResponseFromJSONTyped(json, false);
}

export function GetDataSourcesQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDataSourcesQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stockMarketIndexClient': !exists(json, 'stockMarketIndexClient') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['stockMarketIndexClient']),
        'stockMarketIndexComponentClient': !exists(json, 'stockMarketIndexComponentClient') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['stockMarketIndexComponentClient']),
        'currencyQuotesClient': !exists(json, 'currencyQuotesClient') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['currencyQuotesClient']),
        'nobleMetalClient': !exists(json, 'nobleMetalClient') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['nobleMetalClient']),
        'stockRecommendationProvider': !exists(json, 'stockRecommendationProvider') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['stockRecommendationProvider']),
        'stockIndicatorProvider': !exists(json, 'stockIndicatorProvider') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['stockIndicatorProvider']),
        'companyFinancialDataProvider': !exists(json, 'companyFinancialDataProvider') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['companyFinancialDataProvider']),
        'stockDividendProvider': !exists(json, 'stockDividendProvider') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['stockDividendProvider']),
        'stackQuotesClient': !exists(json, 'stackQuotesClient') ? undefined : GetDataSourcesQueryResponseClientInfoFromJSON(json['stackQuotesClient']),
    };
}

export function GetDataSourcesQueryResponseToJSON(value?: GetDataSourcesQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockMarketIndexClient': GetDataSourcesQueryResponseClientInfoToJSON(value.stockMarketIndexClient),
        'stockMarketIndexComponentClient': GetDataSourcesQueryResponseClientInfoToJSON(value.stockMarketIndexComponentClient),
        'currencyQuotesClient': GetDataSourcesQueryResponseClientInfoToJSON(value.currencyQuotesClient),
        'nobleMetalClient': GetDataSourcesQueryResponseClientInfoToJSON(value.nobleMetalClient),
        'stockRecommendationProvider': GetDataSourcesQueryResponseClientInfoToJSON(value.stockRecommendationProvider),
        'stockIndicatorProvider': GetDataSourcesQueryResponseClientInfoToJSON(value.stockIndicatorProvider),
        'companyFinancialDataProvider': GetDataSourcesQueryResponseClientInfoToJSON(value.companyFinancialDataProvider),
        'stockDividendProvider': GetDataSourcesQueryResponseClientInfoToJSON(value.stockDividendProvider),
        'stackQuotesClient': GetDataSourcesQueryResponseClientInfoToJSON(value.stackQuotesClient),
    };
}

