import { Row } from 'react-bootstrap'
import { emptyIdNamePair } from '../../api-client/extensions/IdNamePairExtensions'
import { AssetTypeEnum, IdNamePair, IdNamePairHierarchy } from '../../api-client/generated'
import { useEffect, useState } from 'react'
import { homeApiFactory } from '../../api-client/factory'
import { Typeahead } from 'react-bootstrap-typeahead'

interface Props {
  initAssetTypeValue?: any
  onAssetTypeChange?: (value: AssetTypeEnum) => void
  onResourceChange: (value: number) => void
}

const ChooseAssetResource = (props: Props) => {
  const [availableResources, setAvailableResources] = useState<IdNamePair[]>([])

  const [allAssetsTypesWithResources, setAllAssetsTypesWithResources] =
    useState<any[]>([])

  useEffect(() => {
    homeApiFactory()
      .apiHomeDictAllAssetsGet()
      .then((response: IdNamePairHierarchy[]) => {
        const items: IdNamePairHierarchy[] = [
          { key: emptyIdNamePair, items: [] }
        ]
        items.push(...response)

        setAllAssetsTypesWithResources(items)
      })
  }, [])

  useEffect(() => {
    if (!!props.initAssetTypeValue?.id) {
      onAssetTypeChange(props.initAssetTypeValue.id)
    }
  }, [props.initAssetTypeValue])

  const onAssetTypeChange = (newAssetTypeId: AssetTypeEnum) => {
    const resourceItems = allAssetsTypesWithResources.find(
      (p) => p.key.id === Number(newAssetTypeId)
    ).items

    const items: IdNamePair[] = [emptyIdNamePair]
    items.push(...resourceItems)

    setAvailableResources(items)

    props.onAssetTypeChange?.(newAssetTypeId)
  }

  const renderValue = () => {
    if (!!props.initAssetTypeValue) {
      return <span>{props.initAssetTypeValue.name}</span>
    } else {
      return (
        <Typeahead
          id="chooseAssetType"
          labelKey="name"
          options={allAssetsTypesWithResources.map((p) => ({
            id: p.key.id,
            name: p.key.name
          }))}
          onInputChange={(text) => console.log(text)}
          onChange={(p) => {
            const selected = p[0] as any
            if (!!selected) {
              onAssetTypeChange(selected.id)
            }
          }}
        />
      )
    }
  }

  return (
    <>
      <Row className="mar-b-1">
        <label>Asset type</label>
        {renderValue()}
      </Row>
      <Row className="mar-b-2">
        <label>Resource Name</label>
        <Typeahead
          id="chooseResource"
          labelKey="name"
          options={availableResources.map((p) => ({
            id: p.id!,
            name: p.name!
          }))}
          onInputChange={(text) => console.log(text)}
          onChange={(p) => {
            const selected = p[0] as any
            if (!!selected) {
              props.onResourceChange(Number(selected.id))
            }
          }}
        />
      </Row>
    </>
  )
}

export default ChooseAssetResource
