/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AlertRuleTypeEnum {
    ThresholdAlertCheckerStack = 'ThresholdAlertCheckerStack',
    ThresholdAlertCheckerCurrency = 'ThresholdAlertCheckerCurrency',
    ThresholdAlertCheckerMarketIndex = 'ThresholdAlertCheckerMarketIndex',
    CurrentValueBelowBuyValueStack = 'CurrentValueBelowBuyValueStack',
    CurrentValueBelowBuyValueCurrency = 'CurrentValueBelowBuyValueCurrency'
}

export function AlertRuleTypeEnumFromJSON(json: any): AlertRuleTypeEnum {
    return AlertRuleTypeEnumFromJSONTyped(json, false);
}

export function AlertRuleTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRuleTypeEnum {
    return json as AlertRuleTypeEnum;
}

export function AlertRuleTypeEnumToJSON(value?: AlertRuleTypeEnum | null): any {
    return value as any;
}

