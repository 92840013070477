/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrencyQuoteDetailsItem
 */
export interface CurrencyQuoteDetailsItem {
    /**
     * 
     * @type {Date}
     * @memberof CurrencyQuoteDetailsItem
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof CurrencyQuoteDetailsItem
     */
    sellValue?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyQuoteDetailsItem
     */
    buyValue?: number;
}

export function CurrencyQuoteDetailsItemFromJSON(json: any): CurrencyQuoteDetailsItem {
    return CurrencyQuoteDetailsItemFromJSONTyped(json, false);
}

export function CurrencyQuoteDetailsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyQuoteDetailsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'sellValue': !exists(json, 'sellValue') ? undefined : json['sellValue'],
        'buyValue': !exists(json, 'buyValue') ? undefined : json['buyValue'],
    };
}

export function CurrencyQuoteDetailsItemToJSON(value?: CurrencyQuoteDetailsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'sellValue': value.sellValue,
        'buyValue': value.buyValue,
    };
}

