import React from 'react'
import { toISOStringDate } from '../../../extensions/DateExtensions'
import styles from './DateRange.module.scss'

interface Props {
  startDate?: Date
  endDate?: Date
  disabled?: boolean
  onChange?: (startDate: Date | undefined, endDate: Date | undefined) => void
}

const DateRangeComponent = (props: Props) => {
  const onStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validate(e.target.valueAsDate, props.endDate)) {
      const value = !!e.target.valueAsDate ? e.target.valueAsDate : undefined
      props.onChange?.(value, props.endDate)
    }
  }

  const onEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validate(props.startDate, e.target.valueAsDate)) {
      const value = !!e.target.valueAsDate ? e.target.valueAsDate : undefined
      props.onChange?.(props.startDate, value)
    }
  }

  const validate = (
    startDate: Date | undefined | null,
    endDate: Date | undefined | null
  ): boolean => {
    if (!!startDate && !!endDate) {
      return endDate >= startDate
    }

    return true
  }

  return (
    <div className={styles.mHalf}>
      <div className={styles.grid}>
        <label>From </label>
        <input
          type="date"
          disabled={props.disabled}
          onChange={(param) => onStartDateChange(param)}
          value={toISOStringDate(props.startDate)}
        />
      </div>
      <div className={styles.grid}>
        <label>To </label>
        <input
          type="date"
          disabled={props.disabled}
          onChange={(param) => onEndDateChange(param)}
          value={toISOStringDate(props.endDate)}
        />
      </div>
    </div>
  )
}

export default DateRangeComponent
