import { useNavigate, useParams } from 'react-router-dom'
import {
  CompanyBasicInfo,
  CompanyDividend,
  CompanyFinancialData,
  CompanyIndicator,
  CompanyRecommendationBasicInfo
} from '../../../../api-client/generated'
import { formatDate } from '../../../../services/culture-service'
import StooqUrlNavigationService from '../../../../services/stooq-url-navigation-service'
import UrlNavigationService from '../../../../services/url-service'
import styles from './StockDetailsSummary.module.scss'
import { formatTousendsSeparator } from '../../../../extensions/MathExtensions'

interface Props {
  basicInfo: CompanyBasicInfo
  indicators?: CompanyIndicator
  financialData?: CompanyFinancialData
  dividend?: CompanyDividend
  recommendation?: CompanyRecommendationBasicInfo
}

const StockDetailsSummary = (props: Props) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const goToStooq = (stortCode: any) => {
    return StooqUrlNavigationService.stockMarketIndexDetails(stortCode)
  }

  const goToStockWatch = (name: any) => {
    return `https://www.stockwatch.pl/gpw/${name},notowania,wskazniki.aspx`
  }

  const navigateToStockDividend = () => {
    navigate(UrlNavigationService.stockDetailsDividend(id!))
  }

  const renderDividend = () => {
    if (!props?.dividend) {
      return <></>
    }

    return (
      <>
        <b>
          <a href="#" onClick={navigateToStockDividend}>
            Dividend
          </a>{' '}
          (state {formatDate(props.dividend.created)})
        </b>
        <br />
        {props.dividend.dividendValue}PLN on {' '}
        {formatDate(props.dividend.dividendPayDate)}
      </>
    )
  }

  const renderFinancialData = () => {
    if (!props?.financialData) {
      return <></>
    }

    return (
      <div>
        <b>Financial Data (state {formatDate(props.financialData.created)})</b>
        <br />
        EBITDA: {formatTousendsSeparator(props.financialData.ebitda)}
        <br />
        Equity: {formatTousendsSeparator(props.financialData.equity)}
        <br />
      </div>
    )
  }

  const renderIndicators = () => {
    if (!props?.indicators) {
      return <></>
    }

    return (
      <div>
        <b>Indicators (state {formatDate(props.indicators.created)})</b>
        <br />
        P/ER: {props.indicators.priceEarningRating}
        <br />
        P/BV: {props.indicators.priceBookValue}
        <br />
        Ratings: {props.indicators.rating}
        <br />
      </div>
    )
  }

  const renderRecommendation = () => {
    if (!props?.recommendation) {
      return <></>
    }

    return <div>
      <b>Recommendation</b>
      <br/>
      Date: {formatDate(props.recommendation?.issueDate)}
      <br/>
      Action: {props.recommendation?.action}
      <br/>
      Predicted quotation: {props.recommendation?.predictedQuotation} PLN
      <br/>
      Issuer: {props.recommendation?.issuer}
    </div>
  }

  return (
    <div className={styles.stockDetailsSummary}>
      <h1>{props.basicInfo.companyShortCode}</h1>
      <h4>{props.basicInfo.companyName}</h4>
      <hr />
      {renderFinancialData()}
      {renderIndicators()}
      {renderDividend()}
      {renderRecommendation()}
      <hr />
      <a
        className={styles.m1r}
        href={goToStooq(props.basicInfo.companyShortCode)}
      >
        Stooq
      </a>
      <a href={goToStockWatch(props.basicInfo.companyName)}>Stockwatch</a>
    </div>
  )
}

export default StockDetailsSummary
