/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyDividendItem
 */
export interface CompanyDividendItem {
    /**
     * 
     * @type {number}
     * @memberof CompanyDividendItem
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDividendItem
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyDividendItem
     */
    year?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDividendItem
     */
    dividendValue?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDividendItem
     */
    dividendPayDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyDividendItem
     */
    status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDividendItem
     */
    lawToDividendDate?: Date;
}

export function CompanyDividendItemFromJSON(json: any): CompanyDividendItem {
    return CompanyDividendItemFromJSONTyped(json, false);
}

export function CompanyDividendItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDividendItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'year': !exists(json, 'year') ? undefined : json['year'],
        'dividendValue': !exists(json, 'dividendValue') ? undefined : json['dividendValue'],
        'dividendPayDate': !exists(json, 'dividendPayDate') ? undefined : (new Date(json['dividendPayDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'lawToDividendDate': !exists(json, 'lawToDividendDate') ? undefined : (new Date(json['lawToDividendDate'])),
    };
}

export function CompanyDividendItemToJSON(value?: CompanyDividendItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'year': value.year,
        'dividendValue': value.dividendValue,
        'dividendPayDate': value.dividendPayDate === undefined ? undefined : (value.dividendPayDate.toISOString()),
        'status': value.status,
        'lawToDividendDate': value.lawToDividendDate === undefined ? undefined : (value.lawToDividendDate.toISOString()),
    };
}

