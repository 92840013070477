/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetLatestResourcePriceQueryResponse
 */
export interface GetLatestResourcePriceQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof GetLatestResourcePriceQueryResponse
     */
    currentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLatestResourcePriceQueryResponse
     */
    buyValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetLatestResourcePriceQueryResponse
     */
    boughtCount?: number | null;
}

export function GetLatestResourcePriceQueryResponseFromJSON(json: any): GetLatestResourcePriceQueryResponse {
    return GetLatestResourcePriceQueryResponseFromJSONTyped(json, false);
}

export function GetLatestResourcePriceQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLatestResourcePriceQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentValue': !exists(json, 'currentValue') ? undefined : json['currentValue'],
        'buyValue': !exists(json, 'buyValue') ? undefined : json['buyValue'],
        'boughtCount': !exists(json, 'boughtCount') ? undefined : json['boughtCount'],
    };
}

export function GetLatestResourcePriceQueryResponseToJSON(value?: GetLatestResourcePriceQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentValue': value.currentValue,
        'buyValue': value.buyValue,
        'boughtCount': value.boughtCount,
    };
}

