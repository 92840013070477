import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { homeApiFactory } from '../../api-client/factory'
import { useState } from 'react'

const AlertTypes = () => {
  const colDef: ColDef[] = [{ field: 'id' }, { field: 'name' }]

  const [rows, setRows] = useState<any[]>([])

  homeApiFactory()
    .apiHomeDictAllAlertRuleTypesGet()
    .then((response) => {
      setRows(response)
    })

  return (
    <>
      <h1>Alert Types</h1>
      <div
        className="ag-theme-alpine"
        style={{ width: '100%', height: '80vh' }}
      >
        <AgGridReact columnDefs={colDef} rowData={rows} />
      </div>
    </>
  )
}

export default AlertTypes
