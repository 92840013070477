/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetNobleMetalQuotationsQueryResponse,
    GetNobleMetalQuotationsQueryResponseFromJSON,
    GetNobleMetalQuotationsQueryResponseToJSON,
    NobleMetalEnum,
    NobleMetalEnumFromJSON,
    NobleMetalEnumToJSON,
} from '../models';

export interface ApiNobleMetalGetRequest {
    metal?: NobleMetalEnum;
    from?: Date;
    to?: Date;
}

/**
 * 
 */
export class NobleMetalApi extends runtime.BaseAPI {

    /**
     */
    async apiNobleMetalGetRaw(requestParameters: ApiNobleMetalGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetNobleMetalQuotationsQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.metal !== undefined) {
            queryParameters['metal'] = requestParameters.metal;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/NobleMetal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNobleMetalQuotationsQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiNobleMetalGet(requestParameters: ApiNobleMetalGetRequest, initOverrides?: RequestInit): Promise<GetNobleMetalQuotationsQueryResponse> {
        const response = await this.apiNobleMetalGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
