/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StockRecommendationAction,
    StockRecommendationActionFromJSON,
    StockRecommendationActionFromJSONTyped,
    StockRecommendationActionToJSON,
} from './StockRecommendationAction';

/**
 * 
 * @export
 * @interface CompanyRecommendationBasicInfo
 */
export interface CompanyRecommendationBasicInfo {
    /**
     * 
     * @type {StockRecommendationAction}
     * @memberof CompanyRecommendationBasicInfo
     */
    actionId?: StockRecommendationAction;
    /**
     * 
     * @type {string}
     * @memberof CompanyRecommendationBasicInfo
     */
    action?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CompanyRecommendationBasicInfo
     */
    issueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CompanyRecommendationBasicInfo
     */
    predictedQuotation?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyRecommendationBasicInfo
     */
    issuer?: string | null;
}

export function CompanyRecommendationBasicInfoFromJSON(json: any): CompanyRecommendationBasicInfo {
    return CompanyRecommendationBasicInfoFromJSONTyped(json, false);
}

export function CompanyRecommendationBasicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRecommendationBasicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionId': !exists(json, 'actionId') ? undefined : StockRecommendationActionFromJSON(json['actionId']),
        'action': !exists(json, 'action') ? undefined : json['action'],
        'issueDate': !exists(json, 'issueDate') ? undefined : (new Date(json['issueDate'])),
        'predictedQuotation': !exists(json, 'predictedQuotation') ? undefined : json['predictedQuotation'],
        'issuer': !exists(json, 'issuer') ? undefined : json['issuer'],
    };
}

export function CompanyRecommendationBasicInfoToJSON(value?: CompanyRecommendationBasicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionId': StockRecommendationActionToJSON(value.actionId),
        'action': value.action,
        'issueDate': value.issueDate === undefined ? undefined : (value.issueDate.toISOString()),
        'predictedQuotation': value.predictedQuotation,
        'issuer': value.issuer,
    };
}

