/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockTradeGainQuery
 */
export interface StockTradeGainQuery {
    /**
     * 
     * @type {number}
     * @memberof StockTradeGainQuery
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTradeGainQuery
     */
    buyPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTradeGainQuery
     */
    sellPrice?: number;
}

export function StockTradeGainQueryFromJSON(json: any): StockTradeGainQuery {
    return StockTradeGainQueryFromJSONTyped(json, false);
}

export function StockTradeGainQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockTradeGainQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'buyPrice': !exists(json, 'buyPrice') ? undefined : json['buyPrice'],
        'sellPrice': !exists(json, 'sellPrice') ? undefined : json['sellPrice'],
    };
}

export function StockTradeGainQueryToJSON(value?: StockTradeGainQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'buyPrice': value.buyPrice,
        'sellPrice': value.sellPrice,
    };
}

