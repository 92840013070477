import { DateTime } from 'luxon'
import { formatTousendsSeparator, roundTo } from '../extensions/MathExtensions'

export const formatDate = (date: Date | string | undefined | null): string => {
  if (!date) {
    return ''
  }

  const jsDate = new Date(date)
  return DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd')
}

export const formatNumber = (input: number | string | undefined | null, points: number = 2, emptyString = '') => {
  if (!input) {
    return emptyString
  }

  return formatTousendsSeparator(roundTo(input, points))
}
