import {
  ColumnInfo,
  ColumnInfoType
} from './AgGridExtensions/AgGridColumns/ColumnsExtensions'

const getPropertiesFromObject = (obj: any): ColumnInfo[] => {
  if (!obj) {
    return []
  }

  const result: ColumnInfo[] = []
  for (const property in obj) {
    result.push({
      name: property,
      type: mapPropertyToColumnType(obj[property])
    })
  }

  return result
}

// todp: get type of property
const mapPropertyToColumnType: (property: any) => ColumnInfoType = (
  property: any
) => {
  if (typeof property === 'number') {
    return 'number'
  } else if (property instanceof Date) {
    return 'date'
  } else if (typeof property === 'string') {
    return 'text'
  }
  return 'other'
}

export default getPropertiesFromObject
