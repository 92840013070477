/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrencyQuoteDetailsItem,
    CurrencyQuoteDetailsItemFromJSON,
    CurrencyQuoteDetailsItemFromJSONTyped,
    CurrencyQuoteDetailsItemToJSON,
} from './CurrencyQuoteDetailsItem';
import {
    QuotesStats,
    QuotesStatsFromJSON,
    QuotesStatsFromJSONTyped,
    QuotesStatsToJSON,
} from './QuotesStats';

/**
 * 
 * @export
 * @interface GetCurrentCurrencyDetailsQueryResponse
 */
export interface GetCurrentCurrencyDetailsQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCurrentCurrencyDetailsQueryResponse
     */
    currencyName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetCurrentCurrencyDetailsQueryResponse
     */
    statusDate?: Date;
    /**
     * 
     * @type {Array<CurrencyQuoteDetailsItem>}
     * @memberof GetCurrentCurrencyDetailsQueryResponse
     */
    items?: Array<CurrencyQuoteDetailsItem> | null;
    /**
     * 
     * @type {QuotesStats}
     * @memberof GetCurrentCurrencyDetailsQueryResponse
     */
    queryStats?: QuotesStats;
}

export function GetCurrentCurrencyDetailsQueryResponseFromJSON(json: any): GetCurrentCurrencyDetailsQueryResponse {
    return GetCurrentCurrencyDetailsQueryResponseFromJSONTyped(json, false);
}

export function GetCurrentCurrencyDetailsQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrentCurrencyDetailsQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currencyName': !exists(json, 'currencyName') ? undefined : json['currencyName'],
        'statusDate': !exists(json, 'statusDate') ? undefined : (new Date(json['statusDate'])),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(CurrencyQuoteDetailsItemFromJSON)),
        'queryStats': !exists(json, 'queryStats') ? undefined : QuotesStatsFromJSON(json['queryStats']),
    };
}

export function GetCurrentCurrencyDetailsQueryResponseToJSON(value?: GetCurrentCurrencyDetailsQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currencyName': value.currencyName,
        'statusDate': value.statusDate === undefined ? undefined : (value.statusDate.toISOString()),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(CurrencyQuoteDetailsItemToJSON)),
        'queryStats': QuotesStatsToJSON(value.queryStats),
    };
}

