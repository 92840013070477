/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommandResult,
    CommandResultFromJSON,
    CommandResultToJSON,
    CreateAlertRuleQuery,
    CreateAlertRuleQueryFromJSON,
    CreateAlertRuleQueryToJSON,
    GetAllAlertRulesQueryResponse,
    GetAllAlertRulesQueryResponseFromJSON,
    GetAllAlertRulesQueryResponseToJSON,
    GetAllAlertsQueryResponse,
    GetAllAlertsQueryResponseFromJSON,
    GetAllAlertsQueryResponseToJSON,
    IdNamePair,
    IdNamePairFromJSON,
    IdNamePairToJSON,
} from '../models';

export interface ApiAlertRuleRulesIdDeleteRequest {
    id: number;
}

export interface ApiAlertRuleRulesIdGetRequest {
    id: number;
}

export interface ApiAlertRuleRulesPostRequest {
    createAlertRuleQuery?: CreateAlertRuleQuery;
}

/**
 * 
 */
export class AlertRuleApi extends runtime.BaseAPI {

    /**
     */
    async apiAlertRuleRulesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllAlertRulesQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/AlertRule/rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllAlertRulesQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAlertRuleRulesGet(initOverrides?: RequestInit): Promise<GetAllAlertRulesQueryResponse> {
        const response = await this.apiAlertRuleRulesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAlertRuleRulesIdDeleteRaw(requestParameters: ApiAlertRuleRulesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllAlertsQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAlertRuleRulesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/AlertRule/rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllAlertsQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAlertRuleRulesIdDelete(requestParameters: ApiAlertRuleRulesIdDeleteRequest, initOverrides?: RequestInit): Promise<GetAllAlertsQueryResponse> {
        const response = await this.apiAlertRuleRulesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAlertRuleRulesIdGetRaw(requestParameters: ApiAlertRuleRulesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllAlertsQueryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAlertRuleRulesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/AlertRule/rules/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllAlertsQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAlertRuleRulesIdGet(requestParameters: ApiAlertRuleRulesIdGetRequest, initOverrides?: RequestInit): Promise<GetAllAlertsQueryResponse> {
        const response = await this.apiAlertRuleRulesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAlertRuleRulesPostRaw(requestParameters: ApiAlertRuleRulesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommandResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/AlertRule/rules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAlertRuleQueryToJSON(requestParameters.createAlertRuleQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAlertRuleRulesPost(requestParameters: ApiAlertRuleRulesPostRequest, initOverrides?: RequestInit): Promise<CommandResult> {
        const response = await this.apiAlertRuleRulesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAlertRuleRulesTypesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<IdNamePair>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/AlertRule/rules/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNamePairFromJSON));
    }

    /**
     */
    async apiAlertRuleRulesTypesGet(initOverrides?: RequestInit): Promise<Array<IdNamePair>> {
        const response = await this.apiAlertRuleRulesTypesGetRaw(initOverrides);
        return await response.value();
    }

}
