import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ColDef } from 'ag-grid-community'
import { mapNamesToAgGridColumns } from '../../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import getPropertiesFromObject from '../../../../../extensions/getPropertiesFromObject'

interface Props {
  items: any[]
}

const BaseDetailsTable = (props: Props) => {
  const [colDefs, setColDefs] = useState<ColDef[]>([])

  const gridRef = useRef<AgGridReact<any>>(null)

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.sizeColumnsToFit()
  }, [])

  useEffect(() => {
    const firstItem = props.items![0]

    const names = getPropertiesFromObject(firstItem)
    const cols = mapNamesToAgGridColumns(names)

    setColDefs(cols)
  }, [props])

  return (
    <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
      <AgGridReact
        columnDefs={colDefs}
        rowData={props.items}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default BaseDetailsTable
