/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyRateResult,
    CompanyRateResultFromJSON,
    CompanyRateResultFromJSONTyped,
    CompanyRateResultToJSON,
} from './CompanyRateResult';

/**
 * 
 * @export
 * @interface GetTheMostRecommendedCompaniesQueryResponse
 */
export interface GetTheMostRecommendedCompaniesQueryResponse {
    /**
     * 
     * @type {Array<CompanyRateResult>}
     * @memberof GetTheMostRecommendedCompaniesQueryResponse
     */
    items?: Array<CompanyRateResult> | null;
}

export function GetTheMostRecommendedCompaniesQueryResponseFromJSON(json: any): GetTheMostRecommendedCompaniesQueryResponse {
    return GetTheMostRecommendedCompaniesQueryResponseFromJSONTyped(json, false);
}

export function GetTheMostRecommendedCompaniesQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTheMostRecommendedCompaniesQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(CompanyRateResultFromJSON)),
    };
}

export function GetTheMostRecommendedCompaniesQueryResponseToJSON(value?: GetTheMostRecommendedCompaniesQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(CompanyRateResultToJSON)),
    };
}

