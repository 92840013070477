/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrencyQuoteItem,
    CurrencyQuoteItemFromJSON,
    CurrencyQuoteItemFromJSONTyped,
    CurrencyQuoteItemToJSON,
} from './CurrencyQuoteItem';

/**
 * 
 * @export
 * @interface GetCurrentCurrenciesQueryResponse
 */
export interface GetCurrentCurrenciesQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCurrentCurrenciesQueryResponse
     */
    currencyName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetCurrentCurrenciesQueryResponse
     */
    statusDate?: Date;
    /**
     * 
     * @type {Array<CurrencyQuoteItem>}
     * @memberof GetCurrentCurrenciesQueryResponse
     */
    items?: Array<CurrencyQuoteItem> | null;
}

export function GetCurrentCurrenciesQueryResponseFromJSON(json: any): GetCurrentCurrenciesQueryResponse {
    return GetCurrentCurrenciesQueryResponseFromJSONTyped(json, false);
}

export function GetCurrentCurrenciesQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrentCurrenciesQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currencyName': !exists(json, 'currencyName') ? undefined : json['currencyName'],
        'statusDate': !exists(json, 'statusDate') ? undefined : (new Date(json['statusDate'])),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(CurrencyQuoteItemFromJSON)),
    };
}

export function GetCurrentCurrenciesQueryResponseToJSON(value?: GetCurrentCurrenciesQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currencyName': value.currencyName,
        'statusDate': value.statusDate === undefined ? undefined : (value.statusDate.toISOString()),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(CurrencyQuoteItemToJSON)),
    };
}

