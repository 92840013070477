export {}

// todo: nie udaje się zrobić koszernego ExtensionMethod

// declare global {
//   export interface Date {
//     toISOStringDate(): string
//   }
// }

// Date.prototype.toISOStringDate = function(this: Date): string {
//   const x = this.toISOString().split('T')
//   return x[0]
// }

export const toISOStringDate = (date: Date | undefined): string => {
  if (!date) {
    return ''
  }

  const dateAndTime = date.toISOString().split('T')
  return dateAndTime[0]
}
