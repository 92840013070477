const UrlNavigationService = {
  dashboardUrl() {
    return '/dashboard'
  },
  stockDetails(id: number) {
    return `/stocks/${id}`
  },
  stockDetailsDividend(id: string) {
    return `/stocks/${id}/dividend`
  },
  currencyDetails(id: number) {
    return `/currencies/${id}`
  },
  nobleMetalsDetails(id: string) {
    return `/noble-metals/${id}`
  },
  marketIndexDetails(name: string) {
    return `/market-indexes/${name}`
  },
  marketIndexComponents(name: string) {
    return `/market-indexes/${name}/components`
  },
  addAlertRule() {
    return '/alert-rules/add'
  },
  alertsRulesList() {
    return '/alert-rules'
  }
}

export default UrlNavigationService
