import { DateTime } from 'luxon'
import { DateRangeModel } from './DateRangeModel'

export enum PredefinedDateRangeEnum {
  Month,
  Quarter,
  Year,
  TwoYears,
  ThreeYears,
  FiverYears,
  Custom,
  None
}

export const mapToDateRange = (
  type: PredefinedDateRangeEnum,
  baseDate: Date = new Date(),
  secondDate: Date = new Date()
): DateRangeModel | undefined => {
  switch (type) {
    case PredefinedDateRangeEnum.Custom:
      return {
        startDate: baseDate,
        endDate: secondDate
      }
    case PredefinedDateRangeEnum.Month:
      return {
        startDate: DateTime.fromJSDate(baseDate).plus({ month: -1 }).toJSDate(),
        endDate: baseDate
      }
    case PredefinedDateRangeEnum.Quarter:
      return {
        startDate: DateTime.fromJSDate(baseDate).plus({ month: -3 }).toJSDate(),
        endDate: baseDate
      }
    case PredefinedDateRangeEnum.Year:
      return {
        startDate: DateTime.fromJSDate(baseDate).plus({ year: -1 }).toJSDate(),
        endDate: baseDate
      }
    case PredefinedDateRangeEnum.TwoYears:
      return {
        startDate: DateTime.fromJSDate(baseDate).plus({ year: -2 }).toJSDate(),
        endDate: baseDate
      }
    case PredefinedDateRangeEnum.ThreeYears:
      return {
        startDate: DateTime.fromJSDate(baseDate).plus({ year: -3 }).toJSDate(),
        endDate: baseDate
      }
    case PredefinedDateRangeEnum.FiverYears:
      return {
        startDate: DateTime.fromJSDate(baseDate).plus({ year: -5 }).toJSDate(),
        endDate: baseDate
      }
    case PredefinedDateRangeEnum.None:
    default:
      return {
        startDate: undefined,
        endDate: undefined
      }
  }
}
