import { ICellRendererParams } from 'ag-grid-community'

interface Props extends ICellRendererParams {
  getText: (params: any) => string
  onClick: (params: any) => void
}

const AgGridTextWithButton = (props: Props) => {
  return (
    <>
      <button onClick={() => props.onClick(props)}>ref</button>{' '}{props.getText(props)}
    </>
  )
}

export default AgGridTextWithButton
