import { useEffect, useState } from 'react'
import { assistantApiFactory } from '../../../../api-client/factory'
import {
  CompanyRateResult,
  GetTheMostRecommendedCompaniesQueryResponse
} from '../../../../api-client/generated'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import styles from './AssistantBox.module.scss'

const AssistantBox = () => {
  const [rows, setRows] = useState<CompanyRateResult[]>([])
  const [cols, setCols] = useState<ColDef[]>([])

  useEffect(() => {
    const columns = [
      { field: 'companyName' },
      {
        field: 'relevancy',
        sortable: true,
        valueGetter: (params: any) => {
          return params.data.relevancy?.total
        }
      },
      {
        field: 'priceBookValue',
        valueGetter: (params: any) => {
          return params.data.inputModel.priceBookValue?.value
        }
      },
      {
        field: 'priceEarningRating',
        valueGetter: (params: any) => {
          return params.data.inputModel.priceEarningRating?.value
        }
      },
      {
        field: 'rating',
        valueGetter: (params: any) => {
          return params.data.inputModel.rating?.value
        }
      },
      {
        field: 'recommendationCount',
        valueGetter: (params: any) => {
          return params.data.inputModel.recommendations?.length
        }
      },
      {
        field: 'dividentCount',
        valueGetter: (params: any) => {
          return params.data.inputModel.dividend?.yearWithContinuePaidDividends
        }
      }
    ]
    setCols(columns)

    assistantApiFactory()
      .apiAssistantGetTheMostRecommendedCompaniesGet()
      .then((response: GetTheMostRecommendedCompaniesQueryResponse) => {
        setRows(response.items!)
      })
  }, [])

  return (
    <div className={styles.assistantBox}>
      <div
        className="ag-theme-alpine"
        style={{ width: '100%', height: '100%' }}
      >
        <AgGridReact columnDefs={cols} rowData={rows} />
      </div>
    </div>
  )
}

export default AssistantBox
