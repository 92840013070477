/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BasketResourceEnum,
    BasketResourceEnumFromJSON,
    BasketResourceEnumToJSON,
    GetMyAssetsQueryResponse,
    GetMyAssetsQueryResponseFromJSON,
    GetMyAssetsQueryResponseToJSON,
    TransactionTypeEnum,
    TransactionTypeEnumFromJSON,
    TransactionTypeEnumToJSON,
} from '../models';

export interface ApiUserAssetsAddTransactionPostRequest {
    transactionType?: TransactionTypeEnum;
    assetTypeSelected?: BasketResourceEnum;
    resourceIdSelected?: number;
    resourceAmount?: number;
    resourcePrice?: number;
}

/**
 * 
 */
export class UserAssetsApi extends runtime.BaseAPI {

    /**
     */
    async apiUserAssetsAddTransactionPostRaw(requestParameters: ApiUserAssetsAddTransactionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.transactionType !== undefined) {
            queryParameters['transactionType'] = requestParameters.transactionType;
        }

        if (requestParameters.assetTypeSelected !== undefined) {
            queryParameters['assetTypeSelected'] = requestParameters.assetTypeSelected;
        }

        if (requestParameters.resourceIdSelected !== undefined) {
            queryParameters['resourceIdSelected'] = requestParameters.resourceIdSelected;
        }

        if (requestParameters.resourceAmount !== undefined) {
            queryParameters['resourceAmount'] = requestParameters.resourceAmount;
        }

        if (requestParameters.resourcePrice !== undefined) {
            queryParameters['resourcePrice'] = requestParameters.resourcePrice;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/UserAssets/add-transaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserAssetsAddTransactionPost(requestParameters: ApiUserAssetsAddTransactionPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserAssetsAddTransactionPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUserAssetsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMyAssetsQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/UserAssets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMyAssetsQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserAssetsGet(initOverrides?: RequestInit): Promise<GetMyAssetsQueryResponse> {
        const response = await this.apiUserAssetsGetRaw(initOverrides);
        return await response.value();
    }

}
