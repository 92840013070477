/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StockRecommendationAction {
    Unknown = 'Unknown',
    Hold = 'Hold',
    Buy = 'Buy',
    Sell = 'Sell',
    Accumulate = 'Accumulate',
    Neutral = 'Neutral'
}

export function StockRecommendationActionFromJSON(json: any): StockRecommendationAction {
    return StockRecommendationActionFromJSONTyped(json, false);
}

export function StockRecommendationActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockRecommendationAction {
    return json as StockRecommendationAction;
}

export function StockRecommendationActionToJSON(value?: StockRecommendationAction | null): any {
    return value as any;
}

