/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValueDayAgo,
    ValueDayAgoFromJSON,
    ValueDayAgoFromJSONTyped,
    ValueDayAgoToJSON,
} from './ValueDayAgo';

/**
 * 
 * @export
 * @interface GetAllCompanyDto
 */
export interface GetAllCompanyDto {
    /**
     * 
     * @type {number}
     * @memberof GetAllCompanyDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAllCompanyDto
     */
    shortCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllCompanyDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllCompanyDto
     */
    currentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllCompanyDto
     */
    pricePerBookValue?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllCompanyDto
     */
    pricePerEarningsRatio?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllCompanyDto
     */
    trendWeek?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllCompanyDto
     */
    trendMonth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllCompanyDto
     */
    recommendation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllCompanyDto
     */
    marketIndexes?: string | null;
    /**
     * 
     * @type {Array<ValueDayAgo>}
     * @memberof GetAllCompanyDto
     */
    prevValues?: Array<ValueDayAgo> | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllCompanyDto
     */
    ownedStocksCount?: number | null;
}

export function GetAllCompanyDtoFromJSON(json: any): GetAllCompanyDto {
    return GetAllCompanyDtoFromJSONTyped(json, false);
}

export function GetAllCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'shortCode': !exists(json, 'shortCode') ? undefined : json['shortCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'currentValue': !exists(json, 'currentValue') ? undefined : json['currentValue'],
        'pricePerBookValue': !exists(json, 'pricePerBookValue') ? undefined : json['pricePerBookValue'],
        'pricePerEarningsRatio': !exists(json, 'pricePerEarningsRatio') ? undefined : json['pricePerEarningsRatio'],
        'trendWeek': !exists(json, 'trendWeek') ? undefined : json['trendWeek'],
        'trendMonth': !exists(json, 'trendMonth') ? undefined : json['trendMonth'],
        'recommendation': !exists(json, 'recommendation') ? undefined : json['recommendation'],
        'marketIndexes': !exists(json, 'marketIndexes') ? undefined : json['marketIndexes'],
        'prevValues': !exists(json, 'prevValues') ? undefined : (json['prevValues'] === null ? null : (json['prevValues'] as Array<any>).map(ValueDayAgoFromJSON)),
        'ownedStocksCount': !exists(json, 'ownedStocksCount') ? undefined : json['ownedStocksCount'],
    };
}

export function GetAllCompanyDtoToJSON(value?: GetAllCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shortCode': value.shortCode,
        'name': value.name,
        'currentValue': value.currentValue,
        'pricePerBookValue': value.pricePerBookValue,
        'pricePerEarningsRatio': value.pricePerEarningsRatio,
        'trendWeek': value.trendWeek,
        'trendMonth': value.trendMonth,
        'recommendation': value.recommendation,
        'marketIndexes': value.marketIndexes,
        'prevValues': value.prevValues === undefined ? undefined : (value.prevValues === null ? null : (value.prevValues as Array<any>).map(ValueDayAgoToJSON)),
        'ownedStocksCount': value.ownedStocksCount,
    };
}

