import { ColDef } from 'ag-grid-community'

export const numberFilter: ColDef = {
  filter: 'agNumberColumnFilter'
}

export const textFilter: ColDef = {
  filter: 'agTextColumnFilter'
}

export const dateFilter: ColDef = {
  filter: 'agDateColumnFilter'
}
