/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDividend,
    CompanyDividendFromJSON,
    CompanyDividendFromJSONTyped,
    CompanyDividendToJSON,
} from './CompanyDividend';
import {
    CompanyFinancialData,
    CompanyFinancialDataFromJSON,
    CompanyFinancialDataFromJSONTyped,
    CompanyFinancialDataToJSON,
} from './CompanyFinancialData';
import {
    CompanyIndicator,
    CompanyIndicatorFromJSON,
    CompanyIndicatorFromJSONTyped,
    CompanyIndicatorToJSON,
} from './CompanyIndicator';
import {
    StockMarketIndexEntity,
    StockMarketIndexEntityFromJSON,
    StockMarketIndexEntityFromJSONTyped,
    StockMarketIndexEntityToJSON,
} from './StockMarketIndexEntity';
import {
    StockQuoteEntity,
    StockQuoteEntityFromJSON,
    StockQuoteEntityFromJSONTyped,
    StockQuoteEntityToJSON,
} from './StockQuoteEntity';
import {
    StockRecommendationEntity,
    StockRecommendationEntityFromJSON,
    StockRecommendationEntityFromJSONTyped,
    StockRecommendationEntityToJSON,
} from './StockRecommendationEntity';
import {
    UserAssetsStackEntity,
    UserAssetsStackEntityFromJSON,
    UserAssetsStackEntityFromJSONTyped,
    UserAssetsStackEntityToJSON,
} from './UserAssetsStackEntity';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    shortCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    firstQuotationDate?: Date;
    /**
     * 
     * @type {Array<StockMarketIndexEntity>}
     * @memberof Company
     */
    marketIndexes?: Array<StockMarketIndexEntity> | null;
    /**
     * 
     * @type {Array<StockQuoteEntity>}
     * @memberof Company
     */
    quotes?: Array<StockQuoteEntity> | null;
    /**
     * 
     * @type {Array<StockRecommendationEntity>}
     * @memberof Company
     */
    recommendations?: Array<StockRecommendationEntity> | null;
    /**
     * 
     * @type {Array<UserAssetsStackEntity>}
     * @memberof Company
     */
    basketItems?: Array<UserAssetsStackEntity> | null;
    /**
     * 
     * @type {Array<CompanyDividend>}
     * @memberof Company
     */
    companyDividends?: Array<CompanyDividend> | null;
    /**
     * 
     * @type {Array<CompanyIndicator>}
     * @memberof Company
     */
    companyIndicators?: Array<CompanyIndicator> | null;
    /**
     * 
     * @type {Array<CompanyFinancialData>}
     * @memberof Company
     */
    companyFinanceData?: Array<CompanyFinancialData> | null;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'shortCode': !exists(json, 'shortCode') ? undefined : json['shortCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstQuotationDate': !exists(json, 'firstQuotationDate') ? undefined : (new Date(json['firstQuotationDate'])),
        'marketIndexes': !exists(json, 'marketIndexes') ? undefined : (json['marketIndexes'] === null ? null : (json['marketIndexes'] as Array<any>).map(StockMarketIndexEntityFromJSON)),
        'quotes': !exists(json, 'quotes') ? undefined : (json['quotes'] === null ? null : (json['quotes'] as Array<any>).map(StockQuoteEntityFromJSON)),
        'recommendations': !exists(json, 'recommendations') ? undefined : (json['recommendations'] === null ? null : (json['recommendations'] as Array<any>).map(StockRecommendationEntityFromJSON)),
        'basketItems': !exists(json, 'basketItems') ? undefined : (json['basketItems'] === null ? null : (json['basketItems'] as Array<any>).map(UserAssetsStackEntityFromJSON)),
        'companyDividends': !exists(json, 'companyDividends') ? undefined : (json['companyDividends'] === null ? null : (json['companyDividends'] as Array<any>).map(CompanyDividendFromJSON)),
        'companyIndicators': !exists(json, 'companyIndicators') ? undefined : (json['companyIndicators'] === null ? null : (json['companyIndicators'] as Array<any>).map(CompanyIndicatorFromJSON)),
        'companyFinanceData': !exists(json, 'companyFinanceData') ? undefined : (json['companyFinanceData'] === null ? null : (json['companyFinanceData'] as Array<any>).map(CompanyFinancialDataFromJSON)),
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shortCode': value.shortCode,
        'name': value.name,
        'firstQuotationDate': value.firstQuotationDate === undefined ? undefined : (value.firstQuotationDate.toISOString()),
        'marketIndexes': value.marketIndexes === undefined ? undefined : (value.marketIndexes === null ? null : (value.marketIndexes as Array<any>).map(StockMarketIndexEntityToJSON)),
        'quotes': value.quotes === undefined ? undefined : (value.quotes === null ? null : (value.quotes as Array<any>).map(StockQuoteEntityToJSON)),
        'recommendations': value.recommendations === undefined ? undefined : (value.recommendations === null ? null : (value.recommendations as Array<any>).map(StockRecommendationEntityToJSON)),
        'basketItems': value.basketItems === undefined ? undefined : (value.basketItems === null ? null : (value.basketItems as Array<any>).map(UserAssetsStackEntityToJSON)),
        'companyDividends': value.companyDividends === undefined ? undefined : (value.companyDividends === null ? null : (value.companyDividends as Array<any>).map(CompanyDividendToJSON)),
        'companyIndicators': value.companyIndicators === undefined ? undefined : (value.companyIndicators === null ? null : (value.companyIndicators as Array<any>).map(CompanyIndicatorToJSON)),
        'companyFinanceData': value.companyFinanceData === undefined ? undefined : (value.companyFinanceData === null ? null : (value.companyFinanceData as Array<any>).map(CompanyFinancialDataToJSON)),
    };
}

