/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyRatingModel,
    CompanyRatingModelFromJSON,
    CompanyRatingModelFromJSONTyped,
    CompanyRatingModelToJSON,
} from './CompanyRatingModel';
import {
    RelevancyPoints,
    RelevancyPointsFromJSON,
    RelevancyPointsFromJSONTyped,
    RelevancyPointsToJSON,
} from './RelevancyPoints';

/**
 * 
 * @export
 * @interface CompanyRateResult
 */
export interface CompanyRateResult {
    /**
     * 
     * @type {number}
     * @memberof CompanyRateResult
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyRateResult
     */
    companyName?: string | null;
    /**
     * 
     * @type {CompanyRatingModel}
     * @memberof CompanyRateResult
     */
    inputModel?: CompanyRatingModel;
    /**
     * 
     * @type {RelevancyPoints}
     * @memberof CompanyRateResult
     */
    relevancy?: RelevancyPoints;
}

export function CompanyRateResultFromJSON(json: any): CompanyRateResult {
    return CompanyRateResultFromJSONTyped(json, false);
}

export function CompanyRateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRateResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'inputModel': !exists(json, 'inputModel') ? undefined : CompanyRatingModelFromJSON(json['inputModel']),
        'relevancy': !exists(json, 'relevancy') ? undefined : RelevancyPointsFromJSON(json['relevancy']),
    };
}

export function CompanyRateResultToJSON(value?: CompanyRateResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'companyName': value.companyName,
        'inputModel': CompanyRatingModelToJSON(value.inputModel),
        'relevancy': RelevancyPointsToJSON(value.relevancy),
    };
}

