/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyRatingDividendModel,
    CompanyRatingDividendModelFromJSON,
    CompanyRatingDividendModelFromJSONTyped,
    CompanyRatingDividendModelToJSON,
} from './CompanyRatingDividendModel';
import {
    CompanyRatingRecommendationModel,
    CompanyRatingRecommendationModelFromJSON,
    CompanyRatingRecommendationModelFromJSONTyped,
    CompanyRatingRecommendationModelToJSON,
} from './CompanyRatingRecommendationModel';
import {
    TimestampedValue,
    TimestampedValueFromJSON,
    TimestampedValueFromJSONTyped,
    TimestampedValueToJSON,
} from './TimestampedValue';

/**
 * 
 * @export
 * @interface CompanyRatingModel
 */
export interface CompanyRatingModel {
    /**
     * 
     * @type {TimestampedValue}
     * @memberof CompanyRatingModel
     */
    lastQuotation?: TimestampedValue;
    /**
     * 
     * @type {TimestampedValue}
     * @memberof CompanyRatingModel
     */
    priceBookValue?: TimestampedValue;
    /**
     * 
     * @type {number}
     * @memberof CompanyRatingModel
     */
    priceBookValueQuarterTrend?: number | null;
    /**
     * 
     * @type {TimestampedValue}
     * @memberof CompanyRatingModel
     */
    priceEarningRating?: TimestampedValue;
    /**
     * 
     * @type {number}
     * @memberof CompanyRatingModel
     */
    priceEarningRatingQuarterTrend?: number | null;
    /**
     * 
     * @type {TimestampedValue}
     * @memberof CompanyRatingModel
     */
    rating?: TimestampedValue;
    /**
     * 
     * @type {CompanyRatingDividendModel}
     * @memberof CompanyRatingModel
     */
    dividend?: CompanyRatingDividendModel;
    /**
     * 
     * @type {Array<CompanyRatingRecommendationModel>}
     * @memberof CompanyRatingModel
     */
    recommendations?: Array<CompanyRatingRecommendationModel> | null;
}

export function CompanyRatingModelFromJSON(json: any): CompanyRatingModel {
    return CompanyRatingModelFromJSONTyped(json, false);
}

export function CompanyRatingModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRatingModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastQuotation': !exists(json, 'lastQuotation') ? undefined : TimestampedValueFromJSON(json['lastQuotation']),
        'priceBookValue': !exists(json, 'priceBookValue') ? undefined : TimestampedValueFromJSON(json['priceBookValue']),
        'priceBookValueQuarterTrend': !exists(json, 'priceBookValueQuarterTrend') ? undefined : json['priceBookValueQuarterTrend'],
        'priceEarningRating': !exists(json, 'priceEarningRating') ? undefined : TimestampedValueFromJSON(json['priceEarningRating']),
        'priceEarningRatingQuarterTrend': !exists(json, 'priceEarningRatingQuarterTrend') ? undefined : json['priceEarningRatingQuarterTrend'],
        'rating': !exists(json, 'rating') ? undefined : TimestampedValueFromJSON(json['rating']),
        'dividend': !exists(json, 'dividend') ? undefined : CompanyRatingDividendModelFromJSON(json['dividend']),
        'recommendations': !exists(json, 'recommendations') ? undefined : (json['recommendations'] === null ? null : (json['recommendations'] as Array<any>).map(CompanyRatingRecommendationModelFromJSON)),
    };
}

export function CompanyRatingModelToJSON(value?: CompanyRatingModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastQuotation': TimestampedValueToJSON(value.lastQuotation),
        'priceBookValue': TimestampedValueToJSON(value.priceBookValue),
        'priceBookValueQuarterTrend': value.priceBookValueQuarterTrend,
        'priceEarningRating': TimestampedValueToJSON(value.priceEarningRating),
        'priceEarningRatingQuarterTrend': value.priceEarningRatingQuarterTrend,
        'rating': TimestampedValueToJSON(value.rating),
        'dividend': CompanyRatingDividendModelToJSON(value.dividend),
        'recommendations': value.recommendations === undefined ? undefined : (value.recommendations === null ? null : (value.recommendations as Array<any>).map(CompanyRatingRecommendationModelToJSON)),
    };
}

