import { useEffect, useState } from 'react'
import { stockApiFactory } from '../../../../api-client/factory'
import { AgGridReact } from 'ag-grid-react'
import { mapNamesToAgGridColumns } from '../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import getPropertiesFromObject from '../../../../extensions/getPropertiesFromObject'
import styles from './StocksAllDividendList.module.scss'

const StocksAllDividendList = () => {
  const [rows, setRows] = useState<any[]>([])
  const [colDefs, setColDefs] = useState<any[]>([])

  useEffect(() => {
    stockApiFactory()
      .apiStockAllDividendGet()
      .then((response) => {
        const firstItem = response.items![0]
        const names = getPropertiesFromObject(firstItem)

        const excludedColumns = ['id', 'company', 'companyId']
        const cols = mapNamesToAgGridColumns(names, excludedColumns)

        setColDefs(cols)

        setRows(response.items!)
      })
  }, [])

  return (
    <div className={styles.stocksAllDividendList}>
      <h4>Dividends</h4>
      <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
        <AgGridReact rowData={rows} columnDefs={colDefs} />
      </div>
    </div>
  )
}

export default StocksAllDividendList
