import { useEffect, useState } from 'react'
import { homeApiFactory } from '../../api-client/factory'
import { showInfoToastr } from '../../services/toastr-notification-service'
import {
  GetDataSourcesQueryResponse,
  GetDataSourcesQueryResponseClientInfo
} from '../../api-client/generated'
import './DataSource.scss'

const DataSources = () => {
  const [url, setUrl] = useState<string>()
  const [sources, setSources] = useState<GetDataSourcesQueryResponse>()

  useEffect(() => {
    homeApiFactory()
      .apiHomeDataSourcesGet()
      .then((response) => {
        setSources(response)
      })
  }, [])

  const testUrl = () => {
    homeApiFactory()
      .apiHomeDataSourceTestGet({
        testedUrl: url
      })
      .then((resp: string) => showInfoToastr(resp))
  }

  const renderCells = (
    name: string,
    data: GetDataSourcesQueryResponseClientInfo | undefined
  ) => {
    return (
      <tr>
        <td>{name}</td>
        <td>{data?.typeName}</td>
        <td><a href={data?.baseUrl!}>{data?.baseUrl}</a></td>
      </tr>
    )
  }

  return (
    <>
      <h4>Data source</h4>
      <h6>Quotations</h6>
      <table>
        <thead>
          <tr>
            <th>ASSET</th>
            <th>HANDLER NAME</th>
            <th>SAMPLE DATA URL</th>
          </tr>
        </thead>
        <tbody>
          {renderCells('Stack Quotes', sources?.stackQuotesClient)}
          {renderCells('Currency Quotes', sources?.currencyQuotesClient)}
          {renderCells('Market Index Quotes', sources?.stockMarketIndexClient)}
          {renderCells('Noble Metal Quotes', sources?.nobleMetalClient)}
        </tbody>
      </table>
      <br/>
      <h6>Economic data</h6>
      <table>
        <thead>
          <tr>
            <th>ASSET</th>
            <th>HANDLER NAME</th>
            <th>SAMPLE DATA URL</th>
          </tr>
        </thead>
        <tbody>
          {renderCells('Recommendation', sources?.stockRecommendationProvider)}
          {renderCells('Stock Indicators', sources?.stockIndicatorProvider)}
          {renderCells('Finantial Data', sources?.companyFinancialDataProvider)}
          {renderCells('Dividend', sources?.stockDividendProvider)}
          {renderCells('Market Index Components', sources?.stockMarketIndexComponentClient)}
        </tbody>
      </table>

      <hr />
      <div>Waluta - https://api.nbp.pl</div>
      <div>Notowania - https://stooq.pl</div>
      <div>Wskaźniki/dywidendy - https://www.stockwatch.pl</div>
      <hr />
      <input onChange={(p) => setUrl(p.target.value)} />
      <button onClick={() => testUrl()}>test</button>
    </>
  )
}

export default DataSources
