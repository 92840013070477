/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AlertRuleTypeGroupEnum {
    CheckThreshold = 'CheckThreshold',
    CurrentValueBelowBuyValue = 'CurrentValueBelowBuyValue'
}

export function AlertRuleTypeGroupEnumFromJSON(json: any): AlertRuleTypeGroupEnum {
    return AlertRuleTypeGroupEnumFromJSONTyped(json, false);
}

export function AlertRuleTypeGroupEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRuleTypeGroupEnum {
    return json as AlertRuleTypeGroupEnum;
}

export function AlertRuleTypeGroupEnumToJSON(value?: AlertRuleTypeGroupEnum | null): any {
    return value as any;
}

