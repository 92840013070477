/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinearCoefficients
 */
export interface LinearCoefficients {
    /**
     * 
     * @type {number}
     * @memberof LinearCoefficients
     */
    a?: number;
    /**
     * 
     * @type {number}
     * @memberof LinearCoefficients
     */
    b?: number;
}

export function LinearCoefficientsFromJSON(json: any): LinearCoefficients {
    return LinearCoefficientsFromJSONTyped(json, false);
}

export function LinearCoefficientsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinearCoefficients {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'a': !exists(json, 'a') ? undefined : json['a'],
        'b': !exists(json, 'b') ? undefined : json['b'],
    };
}

export function LinearCoefficientsToJSON(value?: LinearCoefficients | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'a': value.a,
        'b': value.b,
    };
}

