import { ColDef, ValueGetterParams } from 'ag-grid-community'
import { dateFilter, numberFilter, textFilter } from './AgGridColumnFilters'
import { formatDate, formatNumber } from '../../../services/culture-service'

export type ColumnInfoType = 'number' | 'date' | 'text' | 'other'

export interface ColumnInfo {
  name: string
  type: ColumnInfoType
}

export const mapNamesToAgGridColumns = (
  names: ColumnInfo[],
  except: string[] = []
): ColDef[] => {
  return names
    .filter((p) => !except.find((r) => r === p.name))
    .map((p) => ({
      field: p.name,
      sortable: true,
      ...valueFormatter(p.type),
      ...filter(p.type)
    }))
}

const filter = (type: ColumnInfoType) => {
  switch (type) {
    case 'number':
      return numberFilter
    case 'date':
      return dateFilter
    case 'text':
      return textFilter
    case 'other':
      return ''
  }
}

const valueFormatter = (type: ColumnInfoType) => {
  switch (type) {
    case 'date':
      return {
        valueFormatter: dateTimeFormatter,
        valueGetter: dateTimeGetter
      }
    case 'number':
      return {
        valueFormatter: numberFormatter
      }
    case 'text':
    case 'other':
      return ''
  }
}

const dateTimeFormatter = (params: any) => {
  const field = params.colDef.field

  const date = params.data[field]
  return formatDate(date)
}

const dateTimeGetter = (params: ValueGetterParams) => {
  const field = params.colDef.field

  const value = params.data[field!]
  return formatDate(value)
}

const numberFormatter = (params: any): string => {
  const field = params.colDef.field

  const num = params.data[field]
  const value = formatNumber(num)
  return !!value ? value! : ''
}
