/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinearCoefficients,
    LinearCoefficientsFromJSON,
    LinearCoefficientsFromJSONTyped,
    LinearCoefficientsToJSON,
} from './LinearCoefficients';
import {
    Quotation,
    QuotationFromJSON,
    QuotationFromJSONTyped,
    QuotationToJSON,
} from './Quotation';

/**
 * 
 * @export
 * @interface LinearEquation
 */
export interface LinearEquation {
    /**
     * 
     * @type {LinearCoefficients}
     * @memberof LinearEquation
     */
    coefficients?: LinearCoefficients;
    /**
     * 
     * @type {Array<Quotation>}
     * @memberof LinearEquation
     */
    items?: Array<Quotation> | null;
}

export function LinearEquationFromJSON(json: any): LinearEquation {
    return LinearEquationFromJSONTyped(json, false);
}

export function LinearEquationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinearEquation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coefficients': !exists(json, 'coefficients') ? undefined : LinearCoefficientsFromJSON(json['coefficients']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(QuotationFromJSON)),
    };
}

export function LinearEquationToJSON(value?: LinearEquation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coefficients': LinearCoefficientsToJSON(value.coefficients),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(QuotationToJSON)),
    };
}

