import { toast } from 'react-toastify'

// DOCS: https://fkhadra.github.io/react-toastify/introduction
export const showErrorToastr = (errorMsg: string) => {
  toast.error(errorMsg, {
    position: 'bottom-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })
}

export const showSuccessToastr = (message: string) => {
  toast.success(message, {
    position: 'bottom-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })
}

export const showInfoToastr = (message: string) => {
  toast.info(message, {
    position: 'bottom-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })
}
