/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommandResult,
    CommandResultFromJSON,
    CommandResultToJSON,
    CompanyBasicInfo,
    CompanyBasicInfoFromJSON,
    CompanyBasicInfoToJSON,
    GetStockMarketIndexDetailsQueryDto,
    GetStockMarketIndexDetailsQueryDtoFromJSON,
    GetStockMarketIndexDetailsQueryDtoToJSON,
    StockMarketIndexListDto,
    StockMarketIndexListDtoFromJSON,
    StockMarketIndexListDtoToJSON,
} from '../models';

export interface ApiStockMarketIndexNameComponentsFetchGetRequest {
    name: string;
}

export interface ApiStockMarketIndexNameComponentsGetRequest {
    name: string;
}

export interface ApiStockMarketIndexNameComponentsPostRequest {
    name: string;
    companyBasicInfo?: Array<CompanyBasicInfo>;
}

export interface ApiStockMarketIndexNameGetRequest {
    name: string;
    from?: Date;
    to?: Date;
}

export interface ApiStockMarketIndexPostRequest {
    body?: string;
}

/**
 * 
 */
export class StockMarketIndexApi extends runtime.BaseAPI {

    /**
     */
    async apiStockMarketIndexGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StockMarketIndexListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/StockMarketIndex`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockMarketIndexListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStockMarketIndexGet(initOverrides?: RequestInit): Promise<StockMarketIndexListDto> {
        const response = await this.apiStockMarketIndexGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockMarketIndexNameComponentsFetchGetRaw(requestParameters: ApiStockMarketIndexNameComponentsFetchGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CompanyBasicInfo>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling apiStockMarketIndexNameComponentsFetchGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/StockMarketIndex/{name}/components/fetch`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyBasicInfoFromJSON));
    }

    /**
     */
    async apiStockMarketIndexNameComponentsFetchGet(requestParameters: ApiStockMarketIndexNameComponentsFetchGetRequest, initOverrides?: RequestInit): Promise<Array<CompanyBasicInfo>> {
        const response = await this.apiStockMarketIndexNameComponentsFetchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockMarketIndexNameComponentsGetRaw(requestParameters: ApiStockMarketIndexNameComponentsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CompanyBasicInfo>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling apiStockMarketIndexNameComponentsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/StockMarketIndex/{name}/components`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyBasicInfoFromJSON));
    }

    /**
     */
    async apiStockMarketIndexNameComponentsGet(requestParameters: ApiStockMarketIndexNameComponentsGetRequest, initOverrides?: RequestInit): Promise<Array<CompanyBasicInfo>> {
        const response = await this.apiStockMarketIndexNameComponentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockMarketIndexNameComponentsPostRaw(requestParameters: ApiStockMarketIndexNameComponentsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommandResult>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling apiStockMarketIndexNameComponentsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/StockMarketIndex/{name}/components`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.companyBasicInfo?.map(CompanyBasicInfoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultFromJSON(jsonValue));
    }

    /**
     */
    async apiStockMarketIndexNameComponentsPost(requestParameters: ApiStockMarketIndexNameComponentsPostRequest, initOverrides?: RequestInit): Promise<CommandResult> {
        const response = await this.apiStockMarketIndexNameComponentsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockMarketIndexNameGetRaw(requestParameters: ApiStockMarketIndexNameGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetStockMarketIndexDetailsQueryDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling apiStockMarketIndexNameGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/StockMarketIndex/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStockMarketIndexDetailsQueryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStockMarketIndexNameGet(requestParameters: ApiStockMarketIndexNameGetRequest, initOverrides?: RequestInit): Promise<GetStockMarketIndexDetailsQueryDto> {
        const response = await this.apiStockMarketIndexNameGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockMarketIndexPostRaw(requestParameters: ApiStockMarketIndexPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommandResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/StockMarketIndex`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandResultFromJSON(jsonValue));
    }

    /**
     */
    async apiStockMarketIndexPost(requestParameters: ApiStockMarketIndexPostRequest, initOverrides?: RequestInit): Promise<CommandResult> {
        const response = await this.apiStockMarketIndexPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
