import { useState } from 'react'
import { assetsApiClient, calculatorApiClient } from '../../../api-client/factory'
import { AssetTypeEnum, StockTradeGainQueryResponse } from '../../../api-client/generated'
import ChooseAssetResource from '../../AlertRules/ChooseAssetResource'
import { formatNumber } from '../../../services/culture-service'
import styles from './TradeStockCalculations.module.scss'

const TradeStockCalculations = () => {
  const [amount, setAmount] = useState<number>(0)
  const [buyPrice, setBuyPrice] = useState<number>(0)
  const [sellPrice, setSellPrice] = useState<number>(0)

  const [assetTypeSelected, setAssetTypeSelected] = useState<AssetTypeEnum | undefined>()

  const [result, setResult] = useState<StockTradeGainQueryResponse>()

  const computeGain = () => {
    calculatorApiClient()
      .apiCalculatorStockTradeGainPost({
        stockTradeGainQuery: {
          buyPrice,
          sellPrice,
          count: amount
        }
      })
      .then((resp) => {
        setResult(resp)
      })
  }

  const handleOnResourceChange = (resourceId: number) => {
    assetsApiClient()
      .apiAssetsResourceTypeResourceIdLastestGet({
        resourceType: assetTypeSelected!,
        resourceId: resourceId.toString()
      })
      .then((p) => {
        setSellPrice(p.currentValue!)

        if (!!p.boughtCount) {
          setAmount(p.boughtCount)
          setBuyPrice(p.buyValue!)
        } else {
          setAmount(0)
          setBuyPrice(0)
        }
      })
  }

  return (
    <>
      <h1>Calculator - buy/sell</h1>
      <div>
        <ChooseAssetResource
          onAssetTypeChange={(p) => setAssetTypeSelected(p)}
          onResourceChange={(p) => handleOnResourceChange(p)}
        />
      </div>
      <div>
        <div className={styles.box}>
          <label>Buy price</label>
          <input
            type="number"
            value={buyPrice}
            onChange={(e) => setBuyPrice(Number(e.target.value))}
          />
          <br />
          <label>Sell Price</label>
          <input
            type="number"
            value={sellPrice}
            onChange={(e) => setSellPrice(Number(e.target.value))}
          />
          <br />
          <label>Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
          />
          <br />
        </div>
        <div className={styles.box}>
          <button onClick={() => computeGain()}>Calculate</button>
        </div>
        <div className={styles.box}>
          <div>
            <label>Brutto gain:</label>
            <span>{formatNumber(result?.totalBruttoGain)} PLN</span>
          </div>
          <div>
            <label>Commission:</label>
            <span>{formatNumber(result?.commission)} PLN</span>
          </div>
          <div>
            <label>Income tax:</label>
            <span>{formatNumber(result?.tax)} PLN</span>
          </div>
          <div>
            <label>Netto gain:</label>
            <span>{formatNumber(result?.totalNettoGain)} PLN</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default TradeStockCalculations
