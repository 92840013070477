/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StockMarketIndexList,
    StockMarketIndexListFromJSON,
    StockMarketIndexListFromJSONTyped,
    StockMarketIndexListToJSON,
} from './StockMarketIndexList';

/**
 * 
 * @export
 * @interface StockMarketIndexListDto
 */
export interface StockMarketIndexListDto {
    /**
     * 
     * @type {Array<StockMarketIndexList>}
     * @memberof StockMarketIndexListDto
     */
    items?: Array<StockMarketIndexList> | null;
    /**
     * 
     * @type {Date}
     * @memberof StockMarketIndexListDto
     */
    statusDate?: Date | null;
}

export function StockMarketIndexListDtoFromJSON(json: any): StockMarketIndexListDto {
    return StockMarketIndexListDtoFromJSONTyped(json, false);
}

export function StockMarketIndexListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMarketIndexListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(StockMarketIndexListFromJSON)),
        'statusDate': !exists(json, 'statusDate') ? undefined : (json['statusDate'] === null ? null : new Date(json['statusDate'])),
    };
}

export function StockMarketIndexListDtoToJSON(value?: StockMarketIndexListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(StockMarketIndexListToJSON)),
        'statusDate': value.statusDate === undefined ? undefined : (value.statusDate === null ? null : value.statusDate.toISOString()),
    };
}

