/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AlertRuleTypeModel,
    AlertRuleTypeModelFromJSON,
    AlertRuleTypeModelToJSON,
    GetDataSourcesQueryResponse,
    GetDataSourcesQueryResponseFromJSON,
    GetDataSourcesQueryResponseToJSON,
    IdNamePair,
    IdNamePairFromJSON,
    IdNamePairToJSON,
    IdNamePairHierarchy,
    IdNamePairHierarchyFromJSON,
    IdNamePairHierarchyToJSON,
} from '../models';

export interface ApiHomeDataSourceTestGetRequest {
    testedUrl?: string;
}

/**
 * 
 */
export class HomeApi extends runtime.BaseAPI {

    /**
     */
    async apiHomeDataSourceTestGetRaw(requestParameters: ApiHomeDataSourceTestGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.testedUrl !== undefined) {
            queryParameters['testedUrl'] = requestParameters.testedUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Home/data-source/test`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiHomeDataSourceTestGet(requestParameters: ApiHomeDataSourceTestGetRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiHomeDataSourceTestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHomeDataSourcesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDataSourcesQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Home/data-sources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDataSourcesQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiHomeDataSourcesGet(initOverrides?: RequestInit): Promise<GetDataSourcesQueryResponse> {
        const response = await this.apiHomeDataSourcesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHomeDictAllAlertRuleTypeGroupsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<IdNamePair>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Home/dict/all-alert-rule-type-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNamePairFromJSON));
    }

    /**
     */
    async apiHomeDictAllAlertRuleTypeGroupsGet(initOverrides?: RequestInit): Promise<Array<IdNamePair>> {
        const response = await this.apiHomeDictAllAlertRuleTypeGroupsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHomeDictAllAlertRuleTypesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AlertRuleTypeModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Home/dict/all-alert-rule-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlertRuleTypeModelFromJSON));
    }

    /**
     */
    async apiHomeDictAllAlertRuleTypesGet(initOverrides?: RequestInit): Promise<Array<AlertRuleTypeModel>> {
        const response = await this.apiHomeDictAllAlertRuleTypesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHomeDictAllAssetsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<IdNamePairHierarchy>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Home/dict/all-assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNamePairHierarchyFromJSON));
    }

    /**
     */
    async apiHomeDictAllAssetsGet(initOverrides?: RequestInit): Promise<Array<IdNamePairHierarchy>> {
        const response = await this.apiHomeDictAllAssetsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHomeStatusGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Home/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHomeStatusGet(initOverrides?: RequestInit): Promise<void> {
        await this.apiHomeStatusGetRaw(initOverrides);
    }

}
