/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DateTimeQuarterEnum {
    Q1 = 'Q1',
    Q2 = 'Q2',
    Q3 = 'Q3',
    Q4 = 'Q4'
}

export function DateTimeQuarterEnumFromJSON(json: any): DateTimeQuarterEnum {
    return DateTimeQuarterEnumFromJSONTyped(json, false);
}

export function DateTimeQuarterEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateTimeQuarterEnum {
    return json as DateTimeQuarterEnum;
}

export function DateTimeQuarterEnumToJSON(value?: DateTimeQuarterEnum | null): any {
    return value as any;
}

