/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAllAlertRulesQueryResponseItem,
    GetAllAlertRulesQueryResponseItemFromJSON,
    GetAllAlertRulesQueryResponseItemFromJSONTyped,
    GetAllAlertRulesQueryResponseItemToJSON,
} from './GetAllAlertRulesQueryResponseItem';

/**
 * 
 * @export
 * @interface GetAllAlertRulesQueryResponse
 */
export interface GetAllAlertRulesQueryResponse {
    /**
     * 
     * @type {Array<GetAllAlertRulesQueryResponseItem>}
     * @memberof GetAllAlertRulesQueryResponse
     */
    items?: Array<GetAllAlertRulesQueryResponseItem> | null;
}

export function GetAllAlertRulesQueryResponseFromJSON(json: any): GetAllAlertRulesQueryResponse {
    return GetAllAlertRulesQueryResponseFromJSONTyped(json, false);
}

export function GetAllAlertRulesQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllAlertRulesQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(GetAllAlertRulesQueryResponseItemFromJSON)),
    };
}

export function GetAllAlertRulesQueryResponseToJSON(value?: GetAllAlertRulesQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(GetAllAlertRulesQueryResponseItemToJSON)),
    };
}

