/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssetTypeEnum,
    AssetTypeEnumFromJSON,
    AssetTypeEnumToJSON,
    GetLatestResourcePriceQueryResponse,
    GetLatestResourcePriceQueryResponseFromJSON,
    GetLatestResourcePriceQueryResponseToJSON,
} from '../models';

export interface ApiAssetsResourceTypeResourceIdLastestGetRequest {
    resourceId: string;
    resourceType: string;
    assetType?: AssetTypeEnum;
}

export interface ApiAssetsResourceTypeResourceIdQuotesExcelGetRequest {
    resourceType: AssetTypeEnum;
    resourceId: string;
    from?: Date;
    to?: Date;
}

/**
 * 
 */
export class AssetsApi extends runtime.BaseAPI {

    /**
     */
    async apiAssetsResourceTypeResourceIdLastestGetRaw(requestParameters: ApiAssetsResourceTypeResourceIdLastestGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetLatestResourcePriceQueryResponse>> {
        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling apiAssetsResourceTypeResourceIdLastestGet.');
        }

        if (requestParameters.resourceType === null || requestParameters.resourceType === undefined) {
            throw new runtime.RequiredError('resourceType','Required parameter requestParameters.resourceType was null or undefined when calling apiAssetsResourceTypeResourceIdLastestGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.assetType !== undefined) {
            queryParameters['assetType'] = requestParameters.assetType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Assets/{resourceType}/{resourceId}/lastest`.replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))).replace(`{${"resourceType"}}`, encodeURIComponent(String(requestParameters.resourceType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLatestResourcePriceQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAssetsResourceTypeResourceIdLastestGet(requestParameters: ApiAssetsResourceTypeResourceIdLastestGetRequest, initOverrides?: RequestInit): Promise<GetLatestResourcePriceQueryResponse> {
        const response = await this.apiAssetsResourceTypeResourceIdLastestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAssetsResourceTypeResourceIdQuotesExcelGetRaw(requestParameters: ApiAssetsResourceTypeResourceIdQuotesExcelGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourceType === null || requestParameters.resourceType === undefined) {
            throw new runtime.RequiredError('resourceType','Required parameter requestParameters.resourceType was null or undefined when calling apiAssetsResourceTypeResourceIdQuotesExcelGet.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling apiAssetsResourceTypeResourceIdQuotesExcelGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Assets/{resourceType}/{resourceId}/quotes/excel`.replace(`{${"resourceType"}}`, encodeURIComponent(String(requestParameters.resourceType))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAssetsResourceTypeResourceIdQuotesExcelGet(requestParameters: ApiAssetsResourceTypeResourceIdQuotesExcelGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAssetsResourceTypeResourceIdQuotesExcelGetRaw(requestParameters, initOverrides);
    }

}
