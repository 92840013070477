/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NobleMetalEnum {
    Gold = 'Gold'
}

export function NobleMetalEnumFromJSON(json: any): NobleMetalEnum {
    return NobleMetalEnumFromJSONTyped(json, false);
}

export function NobleMetalEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NobleMetalEnum {
    return json as NobleMetalEnum;
}

export function NobleMetalEnumToJSON(value?: NobleMetalEnum | null): any {
    return value as any;
}

