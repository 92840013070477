/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertRuleConditionTypeEnum,
    AlertRuleConditionTypeEnumFromJSON,
    AlertRuleConditionTypeEnumFromJSONTyped,
    AlertRuleConditionTypeEnumToJSON,
} from './AlertRuleConditionTypeEnum';
import {
    RelationEnum,
    RelationEnumFromJSON,
    RelationEnumFromJSONTyped,
    RelationEnumToJSON,
} from './RelationEnum';

/**
 * 
 * @export
 * @interface CreateAlertRuleQueryCondition
 */
export interface CreateAlertRuleQueryCondition {
    /**
     * 
     * @type {AlertRuleConditionTypeEnum}
     * @memberof CreateAlertRuleQueryCondition
     */
    alertRuleConditionType?: AlertRuleConditionTypeEnum;
    /**
     * 
     * @type {RelationEnum}
     * @memberof CreateAlertRuleQueryCondition
     */
    relation?: RelationEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateAlertRuleQueryCondition
     */
    threshold?: number;
}

export function CreateAlertRuleQueryConditionFromJSON(json: any): CreateAlertRuleQueryCondition {
    return CreateAlertRuleQueryConditionFromJSONTyped(json, false);
}

export function CreateAlertRuleQueryConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAlertRuleQueryCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertRuleConditionType': !exists(json, 'alertRuleConditionType') ? undefined : AlertRuleConditionTypeEnumFromJSON(json['alertRuleConditionType']),
        'relation': !exists(json, 'relation') ? undefined : RelationEnumFromJSON(json['relation']),
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
    };
}

export function CreateAlertRuleQueryConditionToJSON(value?: CreateAlertRuleQueryCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertRuleConditionType': AlertRuleConditionTypeEnumToJSON(value.alertRuleConditionType),
        'relation': RelationEnumToJSON(value.relation),
        'threshold': value.threshold,
    };
}

