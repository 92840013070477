import { useNavigate } from 'react-router-dom'
import UrlNavigationService from '../../../../services/url-service'

interface Props {
  indexName: string
}

const MarketIndexDetailsSummary = (props: Props) => {
  const navigate = useNavigate()

  const gotoMarketIndexComponents = () => {
    navigate(UrlNavigationService.marketIndexComponents(props.indexName))
  }

  return (
    <div>
      <h1>{props.indexName}</h1>
      <hr/>
      <button onClick={gotoMarketIndexComponents}>Components</button>
    </div>
  )
}

export default MarketIndexDetailsSummary
