/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAllAlertRulesQueryResponseItem
 */
export interface GetAllAlertRulesQueryResponseItem {
    /**
     * 
     * @type {number}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    alertRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    ruleTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    ruleTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    assetTypeId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    assetTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    resourceId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    resourceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllAlertRulesQueryResponseItem
     */
    condition?: string | null;
}

export function GetAllAlertRulesQueryResponseItemFromJSON(json: any): GetAllAlertRulesQueryResponseItem {
    return GetAllAlertRulesQueryResponseItemFromJSONTyped(json, false);
}

export function GetAllAlertRulesQueryResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllAlertRulesQueryResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertRuleId': !exists(json, 'alertRuleId') ? undefined : json['alertRuleId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ruleTypeId': !exists(json, 'ruleTypeId') ? undefined : json['ruleTypeId'],
        'ruleTypeName': !exists(json, 'ruleTypeName') ? undefined : json['ruleTypeName'],
        'assetTypeId': !exists(json, 'assetTypeId') ? undefined : json['assetTypeId'],
        'assetTypeName': !exists(json, 'assetTypeName') ? undefined : json['assetTypeName'],
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
        'resourceName': !exists(json, 'resourceName') ? undefined : json['resourceName'],
        'condition': !exists(json, 'condition') ? undefined : json['condition'],
    };
}

export function GetAllAlertRulesQueryResponseItemToJSON(value?: GetAllAlertRulesQueryResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alertRuleId': value.alertRuleId,
        'name': value.name,
        'ruleTypeId': value.ruleTypeId,
        'ruleTypeName': value.ruleTypeName,
        'assetTypeId': value.assetTypeId,
        'assetTypeName': value.assetTypeName,
        'resourceId': value.resourceId,
        'resourceName': value.resourceName,
        'condition': value.condition,
    };
}

