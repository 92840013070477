import { GetAllCompanyDto } from '../../../../api-client/generated'
import {
  addDiffValueAndPercent,
  addLinkColumn,
  addColumnWithButton
} from '../../../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'
import { mapNamesToAgGridColumns } from '../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import getPropertiesFromObject from '../../../../extensions/getPropertiesFromObject'
import appNavigate from '../../../../services/app-navigation-service'
import StooqUrlNavigationService from '../../../../services/stooq-url-navigation-service'
import UrlNavigationService from '../../../../services/url-service'

export const stockColumns = (firstItem: GetAllCompanyDto, navigate: any) => {
  const excludedColumn = ['prevValues', 'marketIndexId', 'id']

  const navigateToStockDetails = (row: any) => {
    appNavigate(UrlNavigationService.stockDetails(row.id), navigate)
  }

  const goToStooq = (param: any) => {
    return StooqUrlNavigationService.stockDetails(param.data.shortCode)
  }

  const names = getPropertiesFromObject(firstItem)
  const cols = mapNamesToAgGridColumns(names)

  const gainCol = addDiffValueAndPercent('Gain [1d]')
  cols.push(gainCol)

  const stooqLinkCol = addLinkColumn('stooqLink', 'stooq', goToStooq)
  cols.push(stooqLinkCol)

  const buttonCol = addColumnWithButton('Details', navigateToStockDetails)
  cols.push(buttonCol)

  const colsDef = cols.filter((p) => !excludedColumn.includes(p.field!))
  return colsDef
}
