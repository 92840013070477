import { AgGridReact } from 'ag-grid-react'
import { useEffect, useRef, useState } from 'react'
import { stockMarketIndexApiFactory } from '../../../../api-client/factory'
import { CompanyBasicInfo } from '../../../../api-client/generated'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './MarketIndexDetailsComponents.module.scss'
import {
  ColumnInfo,
  mapNamesToAgGridColumns
} from '../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import { ColDef } from 'ag-grid-community'
import UrlNavigationService from '../../../../services/url-service'
import {
  showErrorToastr,
  showSuccessToastr
} from '../../../../services/toastr-notification-service'

const MarketIndexDetailsComponents = () => {
  const textAreaRef = useRef<any>()

  const [rows, setRows] = useState<CompanyBasicInfo[]>()
  const [colsDefs, setColsDefs] = useState<ColDef[]>()
  const [counter, setCounter] = useState<number>(0)

  const navigate = useNavigate()
  const { name } = useParams()

  useEffect(() => {
    stockMarketIndexApiFactory()
      .apiStockMarketIndexNameComponentsGet({ name: name! })
      .then((response: CompanyBasicInfo[]) => {
        const names: ColumnInfo[] = [
          { name: 'companyName', type: 'text' },
          { name: 'companyShortCode', type: 'text' }
        ]
        const cols = mapNamesToAgGridColumns(names)

        setColsDefs(cols)
        setRows(response)
        setCounter(response.length)
      })
  }, [])

  const parseTextArea = () => {
    parse(textAreaRef.current.value)
  }

  const fetchComponentsFromWeb = () => {
    stockMarketIndexApiFactory()
      .apiStockMarketIndexNameComponentsFetchGet({ name: name! })
      .then((response: CompanyBasicInfo[]) => {
        let content = ''
        for (const item of response) {
          content += `${item.companyShortCode}\t${item.companyName}\r\n`
        }

        textAreaRef.current.value = content
      })
  }

  const parse = (input: string) => {
    const parsedRows = input.replaceAll('"', '').split('\n')
    const result: any[] = []
    for (const row of parsedRows) {
      if (!row || row === '') {
        continue
      }

      const obj = row.split('\t')
      if (!obj) {
        continue
      }

      result.push({
        companyName: obj[1],
        companyShortCode: obj[0]
      })
    }

    setRows(result)
    setCounter(result.length)
  }

  const updateIndexComponents = () => {
    stockMarketIndexApiFactory()
      .apiStockMarketIndexNameComponentsPost({
        name: name!,
        companyBasicInfo: rows
      })
      .then(() => {
        showSuccessToastr('Imported')
      })
      .catch((err) => {
        showErrorToastr(err)
      })
  }

  const gotoMarketIndexDetails = () => {
    navigate(UrlNavigationService.marketIndexDetails(name!))
  }

  return (
    <div className={styles.marketIndexDetailsComponents}>
      <div>
        <button onClick={() => gotoMarketIndexDetails()}>Back</button>
      </div>
      <hr />
      <div className={styles.container}>
        <div className={styles.tableDiv}>
          <button onClick={() => fetchComponentsFromWeb()}>
            Fetch from web
          </button>
          <br/>
          <span>
            or - Paste from{' '}
            <a href={'https://stooq.pl/q/i/?s=' + name?.toLowerCase()}>
              {name}
            </a>{' '}
            in format (shortcode, tab, company name)
          </span>
          <br />
          <textarea ref={textAreaRef} className={styles.textArea} rows={25} />
          <br />
          <button onClick={() => parseTextArea()}>Parse</button>
        </div>
        <div className={styles.tableDiv}>
          <p>Total count: {counter}</p>
          <button onClick={updateIndexComponents}>Upload</button>
          <span> - Save preview in database</span>
          <div
            className="ag-theme-alpine"
            style={{ width: '100%', height: '100%' }}
          >
            <AgGridReact columnDefs={colsDefs} rowData={rows} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketIndexDetailsComponents
