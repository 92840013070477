import { QuotesStats } from '../../../../../api-client/generated'
import './BaseDetailsQuotationsStats.scss'

interface Props {
  stats?: QuotesStats
}

const BaseDetailsQuotationsStats = (props: Props) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>MIN</th>
            <th>MAX</th>
            <th>AVG</th>
            <th>Y/Y</th>
            <th>Q/Q</th>
            <th>M/M</th>
            <th>W/W</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.stats?.min?.toFixed(2)}</td>
            <td>{props.stats?.max?.toFixed(2)}</td>
            <td>{props.stats?.average?.toFixed(2)}</td>
            <td>{props.stats?.yearToTear ?? '-'}</td>
            <td>{props.stats?.quarterToQuarter ?? '-'}</td>
            <td>{props.stats?.monthToMonth}</td>
            <td>{props.stats?.weekToWeek}</td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th>Trend 1W</th>
            <th>Trend 1M</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.stats?.trendWeek?.coefficients?.a?.toFixed(3) ?? '-'}</td>
            <td>{props.stats?.trendMonth?.coefficients?.a?.toFixed(3) ?? '-'}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default BaseDetailsQuotationsStats
