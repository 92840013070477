import React, { FC } from 'react'
import './DashboardBox.scss'

interface Props {
  title: string,
  children: React.ReactNode
}

const DashboardAssetsBox: FC<Props> = (props: Props) => {
  return <div className='dashboard-box'>
    <h4>{props.title}</h4>
    {props.children}
  </div>
}

export default DashboardAssetsBox
