import { FC, useState } from 'react'
import { AlertRuleConditionTypeEnum, RelationEnum } from '../../api-client/generated'

interface Props {
  onChange: (param: any) => void
}

const AlertRuleConditionThresholdForm: FC<Props> = (props: Props) => {
  const [selectedRelationId, setSelectedRelationId] = useState<RelationEnum | undefined>()
  const [choosenValue, setChoosenValue] = useState<number>(0)

  const onSelectChange = (newSelectedRelationId: RelationEnum) => {
    setSelectedRelationId(newSelectedRelationId)

    const formValue: any = {
      relation: newSelectedRelationId,
      threshold: choosenValue,
      alertRuleConditionType: AlertRuleConditionTypeEnum.Threshold
    }

    props.onChange(formValue)
  }

  const onInputChange = (newValue: number) => {
    setChoosenValue(newValue)

    const formValue: any = {
      relation: selectedRelationId,
      threshold: newValue,
      alertRuleConditionType: AlertRuleConditionTypeEnum.Threshold
    }

    props.onChange(formValue)
  }

  return (
    <>
      <label>Choose condition</label>
      <select onChange={(p) => onSelectChange(p.target.value as RelationEnum)}>
        <option key={0} value={0}></option>
        <option key={1} value={1}>&gt;</option>
        <option key={2} value={2}>&lt;</option>
        <option key={3} value={3}>&gt;=</option>
        <option key={4} value={4}>&lt;=</option>
        <option key={5} value={5}>=</option>
      </select>
      <input onChange={(p) => onInputChange(Number(p.target.value))} />
    </>
  )
}

export default AlertRuleConditionThresholdForm
