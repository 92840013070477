import { useEffect, useState } from 'react'
import { homeApiFactory } from '../api-client/factory'
import { IdNamePairHierarchy } from '../api-client/generated'
import { Typeahead } from 'react-bootstrap-typeahead'
import UrlNavigationService from '../services/url-service'
import { TypeEnum } from './Analyzes/MultiChart/TypeEnum'

const SearchBar = () => {
  const [items, setItems] = useState<any[]>([])

  useEffect(() => {
    homeApiFactory()
      .apiHomeDictAllAssetsGet()
      .then((response: IdNamePairHierarchy[]) => {
        const stocks = response[0].items?.map((p) => ({
          id: p.id,
          label: p.name,
          assetType: TypeEnum.Stock,
          url: UrlNavigationService.stockDetails(p.id!)
        }))

        const currencies = response[1].items?.map((p) => ({
          id: p.id,
          label: p.name,
          assetType: TypeEnum.Currency,
          url: UrlNavigationService.currencyDetails(p.id!)
        }))

        const indexes = response[2].items?.map((p) => ({
          id: p.id,
          label: p.name,
          assetType: TypeEnum.MarketIndex,
          url: UrlNavigationService.marketIndexDetails(p.name!)
        }))

        const metals = response[3].items?.map((p) => ({
          id: p.id,
          label: p.name,
          assetType: TypeEnum.MarketIndex,
          url: UrlNavigationService.nobleMetalsDetails(p.name!)
        }))

        setItems([...stocks!, ...currencies!, ...indexes!, ...metals!])
      })
  }, [])

  return <Typeahead
    id="menu-search-bar"
    options={items}
    onChange={(p) => {
      const url = (p[0] as any).url
      window.location = url
    }
  }/>
}

export default SearchBar
