import { FC } from 'react'
import AlertRuleConditionThresholdForm from './AlertRuleConditionThresholdForm'

interface Props {
  onChange: (param: any) => void
}

const AddAlertRuleFormConditionFactory: FC<Props> = (props: Props) => {
  return <AlertRuleConditionThresholdForm onChange={props.onChange} />
}

export default AddAlertRuleFormConditionFactory
