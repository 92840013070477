const StooqUrlNavigationService = {
  stockDetails(stockCode: string) {
    return `https://stooq.pl/q/?s=${stockCode}`
  },
  currencyDetails(currencyCode: string) {
    return `https://stooq.pl/q/?s=${currencyCode.toLocaleLowerCase()}pln`
  },
  stockMarketIndexDetails(indexCode: string) {
    return `https://stooq.pl/q/d/?s=${indexCode}`
  }
}

export default StooqUrlNavigationService
