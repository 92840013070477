import { useEffect, useState } from 'react'
import { stockMarketIndexApiFactory } from '../../../../api-client/factory'
import { StockMarketIndexList } from '../../../../api-client/generated'
import { useNavigate } from 'react-router-dom'
import BaseListPage from '../../../Common/Base/BaseListPage/BaseListPage'
import { MarketIndexesColumns } from './MarketIndexesColumns'
import { ColDef } from 'ag-grid-community'
import AddMarketIndexesModal from './AddMarketIndexesModal'
import { showErrorToastr, showSuccessToastr } from '../../../../services/toastr-notification-service'

const MarketIndexes = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const navigate = useNavigate()

  const showPopup = () => {
    setModalIsOpen(true)
  }

  const closePopup = () => {
    setModalIsOpen(false)
  }

  const addMarketIndex = (indexName: string) => {
    stockMarketIndexApiFactory()
      .apiStockMarketIndexPost({
        body: indexName
      })
      .then(() => {
        showSuccessToastr('Dodano nowy indeks giełdowy')
      })
      .catch((err) => {
        showErrorToastr(err)
      })
      .finally(() => {
        closePopup()
      })
  }

  const [rowData, setRowData] = useState<StockMarketIndexList[]>([])
  const [columns, setColumns] = useState<ColDef[]>([])
  const [statusDate, setStatusDate] = useState<Date | undefined>()

  useEffect(() => {
    stockMarketIndexApiFactory()
      .apiStockMarketIndexGet()
      .then((p) => {
        const firstItem = p.items![0]

        const colsDef = MarketIndexesColumns(firstItem, navigate)
        setColumns(colsDef)

        setRowData(p.items!)

        if (!!p.statusDate) {
          setStatusDate(p.statusDate!)
        }
      })
  }, [])

  return (
    <>
      <button onClick={() => showPopup()}> + Add </button>
      <BaseListPage
        title="Stock indexes"
        statusDate={statusDate!}
        columnsDef={columns}
        rows={rowData}
      />
      <AddMarketIndexesModal
        isModalShown={modalIsOpen}
        onAdd={(p) => addMarketIndex(p)}
        onClose={() => closePopup()}
      />
    </>
  )
}

export default MarketIndexes
