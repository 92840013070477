import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'
import { StockQuote } from '../../../../../api-client/generated'
import styles from './BaseDetailsChart.module.scss'

interface Props {
  items: StockQuote[]
  trends: any[]
  getValue: (item: any) => number
}

const BaseDetailsChart = (props: Props) => {
  let chartItems = props.items?.map((p: any) => ({
    quote: props.getValue(p),
    date: new Date(p.date).toISOString().split('T')[0]
  }) as any)

  for (const trend of props.trends) {
    for (const trendItem of trend.items) {
      let entity = chartItems.find((p) => p.date === trendItem.dateTime)
      if (!entity) {
        entity = {
          date: new Date(trend.dateTime).toISOString().split('T')[0]
        }

        chartItems.push(entity)
      }

      entity[trend.name] = trendItem.value
    }
  }

  chartItems = chartItems.sort((a, b) => {
    return a?.date > b?.date ? 1 : -1
  })

  const minValue = Math.min(...chartItems.map((p) => p.quote))
  const maxValue = Math.max(...chartItems.map((p) => p.quote))

  const minChart = minValue * 0.97
  const maxChart = maxValue * 1.03

  const chartSize = {
    width: 1000,
    height: 350
  }

  const tick = (maxChart - minChart) / 10
  const ticks: number[] = []

  for (let i = 0; i < 10; i++) {
    ticks.push(minChart + i * tick)
  }

  return (
    <>
      <LineChart
        className={styles.chart}
        width={chartSize.width}
        height={chartSize.height}
        data={chartItems}
      >
        <XAxis dataKey="date" />
        <YAxis
          type="number"
          domain={[minChart, maxChart]}
          tickFormatter={(tick) => Number(tick).toFixed(2)}
          ticks={ticks}
        />
        <CartesianGrid stroke="#eee" strokeDasharray="10 10" />
        <Line type="monotone" dataKey="quote" stroke="#8884d8" />
        <Line type="monotone" dataKey="trendWeek" stroke="red" dot={false} />
        <Line type="monotone" dataKey="trendMonth" stroke="red" dot={false} />
      </LineChart>
    </>
  )
}

export default BaseDetailsChart
