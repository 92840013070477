import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { alertRuleApiFactory, homeApiFactory } from '../../api-client/factory'
import {
  AlertRuleTypeModel,
  ApiAlertRuleRulesPostRequest
} from '../../api-client/generated'
import AddAlertRuleFormConditionFactory from './AddAlertRuleFormConditionFactory'
import {
  showErrorToastr,
  showSuccessToastr
} from '../../services/toastr-notification-service'
import { useNavigate } from 'react-router-dom'
import UrlNavigationService from '../../services/url-service'
import ChooseAssetResource from './ChooseAssetResource'
import './AddAlertRuleForm.scss'

const AddAlertRuleForm = () => {
  const navigate = useNavigate()

  // todo: to remvove
  const [allAlertRuleTypes, setAllAlertRuleTypes] = useState<
    AlertRuleTypeModel[]
  >([])

  const [selectedAlertRuleType, setSelectedAlertRuleType] =
    useState<AlertRuleTypeModel>()

  const [ruleName, setRuleName] = useState<string>('')

  const [selectedResourceId, setSelectedResourceId] = useState<number>(0)
  const [choosenFormAlertRuleCondition, setChoosenFormAlertRuleCondition] = useState<any>({})

  const [assetModel, setAssetModel] = useState<any>()

  useEffect(() => {
    homeApiFactory()
      .apiHomeDictAllAlertRuleTypesGet()
      .then((response: AlertRuleTypeModel[]) => {
        setAllAlertRuleTypes(response)
        // todo: użcyie tych encji słownikowych
      })
  }, [])

  // todo: walidacja
  const validate = (values: any) => {
    const errors = {
      name: ''
    }

    if (!values.name) {
      errors.name = 'Required'
    }

    return errors
  }

  const onAlertRuleCondtionChange = (value: any) => {
    setChoosenFormAlertRuleCondition(value)
  }

  const submitForm = () => {
    const formValue: ApiAlertRuleRulesPostRequest = {
      createAlertRuleQuery: {
        name: ruleName,
        alertRuleType: selectedAlertRuleType?.alertRuleType,
        resourceId: selectedResourceId,
        conditions: choosenFormAlertRuleCondition
      }
    }

    alertRuleApiFactory()
      .apiAlertRuleRulesPost(formValue)
      .then(() => {
        showSuccessToastr('Alert rule added')

        navigate(UrlNavigationService.alertsRulesList())
      })
      .catch((error) => {
        console.error(error)
        showErrorToastr('Error: while adding')
      })
  }

  const initFormValue = {
    name: '',
    ruleType: 0,
    assetTypeId: 0,
    resourceName: 0
  }

  const selectRuleType = (value: any) => {
    const sel = allAlertRuleTypes.find((p) => p.id === Number(value.target.value))

    setSelectedAlertRuleType(sel)

    const asset = {
      id: sel?.assetType,
      name: sel?.assetTypeName
    }

    setAssetModel(asset)
  }

  return (
    <Formik
      initialValues={initFormValue}
      validate={validate}
      onSubmit={submitForm}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <Row className="mar-b-1">
              <label>Name</label>
              <input onChange={(p) => setRuleName(p.target.value)} />
            </Row>
            <Row className="mar-b-1">
              <label>Rule Type</label>
              <select onChange={(p) => selectRuleType(p)}>
                {allAlertRuleTypes?.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </select>
            </Row>
            <Row className="mar-b-1">
              <label>Rule Type Group:</label>
              <span>{selectedAlertRuleType?.alertRuleTypeGroupName}</span>
            </Row>
            <Row className="mar-b-1">
              <ChooseAssetResource
                initAssetTypeValue={assetModel}
                onResourceChange={(p) => setSelectedResourceId(p)}
              />
            </Row>
            <Row>
              <hr />
              <AddAlertRuleFormConditionFactory
                onChange={onAlertRuleCondtionChange}
              />
            </Row>
            <Row>
              <button type="submit" onClick={submitForm}>
                Submit
              </button>
            </Row>
          </Container>
        </form>
      )}
    </Formik>
  )
}

export default AddAlertRuleForm
