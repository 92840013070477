import { useEffect, useState } from 'react'
import {
  ApiUserAssetsAddTransactionPostRequest,
  AssetTypeEnum,
  GetMyAssetsQueryResponse
} from '../../api-client/generated'
import { userAssetApiFactory } from '../../api-client/factory'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import { mapNamesToAgGridColumns } from '../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import getPropertiesFromObject from '../../extensions/getPropertiesFromObject'
import BasketAddTransactionModal from './BasketAddTransactionModal'
import styles from './Basket.module.scss'
import {
  showErrorToastr,
  showSuccessToastr
} from '../../services/toastr-notification-service'
import GainSpan from '../Dashboard/boxes/GainSpan'
import _ from 'lodash'
import {
  formatTousendsSeparator,
  roundTo
} from '../../extensions/MathExtensions'

const Basket = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)

  const [stockBasket, setStockBasket] = useState<any[]>([])
  const [currencyBasket, setCurrencyBasket] = useState<any[]>([])

  const [stockBasketCols, setStockBasketCols] = useState<ColDef[]>([])
  const [currencyBasketCols, setCurrencyBasketCols] = useState<ColDef[]>([])

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const [totalValue, setTotalValue] = useState<number>()
  const [totalValueBuy, setTotalValueBuy] = useState<number>()

  const [stockTotalValue, setStockTotalValue] = useState<number>()
  const [stockTotalValueBuy, setStockTotalValueBuy] = useState<number>()

  const [currencyTotalValue, setCurrencyTotalValue] = useState<number>()
  const [currencyTotalValueBuy, setCurrencyTotalValueBuy] = useState<number>()

  useEffect(() => {
    setIsLoadingData(true)

    userAssetApiFactory()
      .apiUserAssetsGet()
      .then((response: GetMyAssetsQueryResponse) => {
        const stocks = response!.assets!.filter(
          (p) => p.type === AssetTypeEnum.Stock
        )
        const currencies = response!.assets!.filter(
          (p) => p.type === AssetTypeEnum.Currency
        )

        const firstItem = stocks[0]

        const names = getPropertiesFromObject(firstItem)
        const cols = mapNamesToAgGridColumns(names, ['type', 'typeName'])

        setStockBasketCols(cols)
        setCurrencyBasketCols(cols)

        const totalStock = _.sum(stocks.map((p) => p.currentValue! * p.count!))
        setStockTotalValue(totalStock)

        const totalBuyStock = _.sum(stocks.map((p) => p.buyValue! * p.count!))
        setStockTotalValueBuy(totalBuyStock)

        const totalCurrency = _.sum(
          currencies.map((p) => p.currentValue! * p.count!)
        )
        setCurrencyTotalValue(totalCurrency)

        const totalBuyCurrency = _.sum(
          currencies.map((p) => p.buyValue! * p.count!)
        )
        setCurrencyTotalValueBuy(totalBuyCurrency)

        const total = totalStock + totalCurrency
        setTotalValue(total)

        const totalBuy = totalBuyStock + totalBuyCurrency
        setTotalValueBuy(totalBuy)

        setStockBasket(stocks)
        setCurrencyBasket(currencies)
      })
      .finally(() => setIsLoadingData(false))
  }, [])

  const addStockTransaction = () => {
    showModal()
  }

  const showModal = () => {
    setModalIsOpen(true)
  }

  const hideModal = () => {
    setModalIsOpen(false)
  }

  const saveTransaction = (param: ApiUserAssetsAddTransactionPostRequest) => {
    userAssetApiFactory()
      .apiUserAssetsAddTransactionPost(param)
      .then(() => {
        showSuccessToastr('Zapisano')
      })
      .catch((err) => {
        showErrorToastr(`Wystąpił błąd - '${err}'`)
      })
  }

  const renderLoader = () => {
    return <div>Is loading...</div>
  }

  const renderPageContent = () => {
    return (
      <>
        <button className={'button'} onClick={() => addStockTransaction()}>
          <span>+ Add transaction</span>
        </button>
        <div className={styles.mainSummaryBox}>
          <div className={'summaryBox'}>
            <div>
              Total value ={' '}
              <b>{formatTousendsSeparator(roundTo(totalValue))}</b>
              [PLN]
            </div>
            <div>
              Gain{' '}
              <GainSpan buyValue={totalValueBuy} currentValue={totalValue} />
            </div>
          </div>

          <div className={'summaryBox'}>
            <div>
              Total value (stocks) ={' '}
              <b>{formatTousendsSeparator(roundTo(stockTotalValue))}</b>[PLN]
            </div>
            <div>
              Gain{' '}
              <GainSpan
                buyValue={stockTotalValueBuy}
                currentValue={stockTotalValue}
              />
            </div>
          </div>

          <div className={'summaryBox'}>
            <div>
              Total value (currency) ={' '}
              <b>{formatTousendsSeparator(roundTo(currencyTotalValue))}</b>[PLN]
            </div>
            <div>
              Gain{' '}
              <GainSpan
                buyValue={currencyTotalValueBuy}
                currentValue={currencyTotalValue}
              />
            </div>
          </div>
        </div>
        Stock
        <br />
        <div className="ag-theme-alpine" style={{ width: '100%', height: 200 }}>
          <AgGridReact rowData={stockBasket} columnDefs={stockBasketCols} />
        </div>
        Currency
        <br />
        <div className="ag-theme-alpine" style={{ width: '100%', height: 200 }}>
          <AgGridReact
            rowData={currencyBasket}
            columnDefs={currencyBasketCols}
          />
        </div>
        <BasketAddTransactionModal
          isModalShown={modalIsOpen}
          onAdd={(param) => {
            saveTransaction(param)
          }}
          onClose={hideModal}
        />
      </>
    )
  }

  return isLoadingData ? renderLoader() : renderPageContent()
}

export default Basket
