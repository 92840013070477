/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinearEquation,
    LinearEquationFromJSON,
    LinearEquationFromJSONTyped,
    LinearEquationToJSON,
} from './LinearEquation';

/**
 * 
 * @export
 * @interface QuotesStats
 */
export interface QuotesStats {
    /**
     * 
     * @type {number}
     * @memberof QuotesStats
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotesStats
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotesStats
     */
    average?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotesStats
     */
    dayToDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotesStats
     */
    weekToWeek?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotesStats
     */
    monthToMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotesStats
     */
    quarterToQuarter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotesStats
     */
    yearToTear?: number | null;
    /**
     * 
     * @type {LinearEquation}
     * @memberof QuotesStats
     */
    trendWeek?: LinearEquation;
    /**
     * 
     * @type {LinearEquation}
     * @memberof QuotesStats
     */
    trendMonth?: LinearEquation;
}

export function QuotesStatsFromJSON(json: any): QuotesStats {
    return QuotesStatsFromJSONTyped(json, false);
}

export function QuotesStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuotesStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'average': !exists(json, 'average') ? undefined : json['average'],
        'dayToDay': !exists(json, 'dayToDay') ? undefined : json['dayToDay'],
        'weekToWeek': !exists(json, 'weekToWeek') ? undefined : json['weekToWeek'],
        'monthToMonth': !exists(json, 'monthToMonth') ? undefined : json['monthToMonth'],
        'quarterToQuarter': !exists(json, 'quarterToQuarter') ? undefined : json['quarterToQuarter'],
        'yearToTear': !exists(json, 'yearToTear') ? undefined : json['yearToTear'],
        'trendWeek': !exists(json, 'trendWeek') ? undefined : LinearEquationFromJSON(json['trendWeek']),
        'trendMonth': !exists(json, 'trendMonth') ? undefined : LinearEquationFromJSON(json['trendMonth']),
    };
}

export function QuotesStatsToJSON(value?: QuotesStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min': value.min,
        'max': value.max,
        'average': value.average,
        'dayToDay': value.dayToDay,
        'weekToWeek': value.weekToWeek,
        'monthToMonth': value.monthToMonth,
        'quarterToQuarter': value.quarterToQuarter,
        'yearToTear': value.yearToTear,
        'trendWeek': LinearEquationToJSON(value.trendWeek),
        'trendMonth': LinearEquationToJSON(value.trendMonth),
    };
}

