/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrencyQuoteItem
 */
export interface CurrencyQuoteItem {
    /**
     * 
     * @type {string}
     * @memberof CurrencyQuoteItem
     */
    currency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CurrencyQuoteItem
     */
    buyValue?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyQuoteItem
     */
    buyValueGain?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyQuoteItem
     */
    sellValue?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyQuoteItem
     */
    sellValueGain?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyQuoteItem
     */
    trendWeek?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CurrencyQuoteItem
     */
    trendMonth?: number | null;
}

export function CurrencyQuoteItemFromJSON(json: any): CurrencyQuoteItem {
    return CurrencyQuoteItemFromJSONTyped(json, false);
}

export function CurrencyQuoteItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyQuoteItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'buyValue': !exists(json, 'buyValue') ? undefined : json['buyValue'],
        'buyValueGain': !exists(json, 'buyValueGain') ? undefined : json['buyValueGain'],
        'sellValue': !exists(json, 'sellValue') ? undefined : json['sellValue'],
        'sellValueGain': !exists(json, 'sellValueGain') ? undefined : json['sellValueGain'],
        'trendWeek': !exists(json, 'trendWeek') ? undefined : json['trendWeek'],
        'trendMonth': !exists(json, 'trendMonth') ? undefined : json['trendMonth'],
    };
}

export function CurrencyQuoteItemToJSON(value?: CurrencyQuoteItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'buyValue': value.buyValue,
        'buyValueGain': value.buyValueGain,
        'sellValue': value.sellValue,
        'sellValueGain': value.sellValueGain,
        'trendWeek': value.trendWeek,
        'trendMonth': value.trendMonth,
    };
}

