/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockMarketIndexQuote
 */
export interface StockMarketIndexQuote {
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuote
     */
    open?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuote
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuote
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexQuote
     */
    close?: number;
    /**
     * 
     * @type {Date}
     * @memberof StockMarketIndexQuote
     */
    date?: Date;
}

export function StockMarketIndexQuoteFromJSON(json: any): StockMarketIndexQuote {
    return StockMarketIndexQuoteFromJSONTyped(json, false);
}

export function StockMarketIndexQuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMarketIndexQuote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'open': !exists(json, 'open') ? undefined : json['open'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'close': !exists(json, 'close') ? undefined : json['close'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
    };
}

export function StockMarketIndexQuoteToJSON(value?: StockMarketIndexQuote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'open': value.open,
        'max': value.max,
        'min': value.min,
        'close': value.close,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
    };
}

