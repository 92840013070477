import { useState } from 'react'
import { stockMarketIndexApiFactory } from '../../../../api-client/factory'
import BaseDetailsPage from '../../../Common/Base/BaseDetailsPage/BaseDetailsPage'
import MarketIndexDetailsSummary from './MarketIndexDetailsSummary'
import { useParams } from 'react-router-dom'
import { AssetTypeEnum, QuotesStats } from '../../../../api-client/generated'

const MarketIndexDetails = () => {
  const [rowData, setRowData] = useState<any[]>([])
  const [quotesStats, setQuotesStats] = useState<QuotesStats>()
  const { name } = useParams()

  const dateRangeChanged = (startDate?: Date, endDate?: Date) => {
    stockMarketIndexApiFactory()
      .apiStockMarketIndexNameGet({
        name: name!,
        from: startDate,
        to: endDate
      })
      .then((response) => {
        setRowData(response.quotes!)
        setQuotesStats(response.queryStats)
      })
  }

  const getIndexValue = (item: any) => {
    return Number(item.close)
  }

  return (
    <BaseDetailsPage
      items={rowData}
      resourceType={AssetTypeEnum.MarketIndex}
      quotesStats={quotesStats}
      resourceId={name!}
      summaryComponent={<MarketIndexDetailsSummary indexName={name!} />}
      dateRangeChanged={dateRangeChanged}
      getValue={getIndexValue}
    />
  )
}

export default MarketIndexDetails
