/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginCommand,
    LoginCommandFromJSON,
    LoginCommandToJSON,
    RegisterNewUserCommand,
    RegisterNewUserCommandFromJSON,
    RegisterNewUserCommandToJSON,
} from '../models';

export interface ApiAccountAuthPostRequest {
    loginCommand?: LoginCommand;
}

export interface ApiAccountRegisterPostRequest {
    registerNewUserCommand?: RegisterNewUserCommand;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     */
    async apiAccountAuthPostRaw(requestParameters: ApiAccountAuthPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Account/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginCommandToJSON(requestParameters.loginCommand),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAccountAuthPost(requestParameters: ApiAccountAuthPostRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiAccountAuthPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccountRegisterPostRaw(requestParameters: ApiAccountRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Account/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterNewUserCommandToJSON(requestParameters.registerNewUserCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccountRegisterPost(requestParameters: ApiAccountRegisterPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAccountRegisterPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAccountSignoutPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Account/signout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccountSignoutPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiAccountSignoutPostRaw(initOverrides);
    }

}
