import React from 'react'
import './index.css'
import App from './components/App/App'
import { Provider } from 'react-redux'
import store from './redux/store'
import { createRoot } from 'react-dom/client'

const root = createRoot(document!.getElementById('root')!)
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
)
