/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyBasicInfo
 */
export interface CompanyBasicInfo {
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicInfo
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyBasicInfo
     */
    companyShortCode?: string | null;
}

export function CompanyBasicInfoFromJSON(json: any): CompanyBasicInfo {
    return CompanyBasicInfoFromJSONTyped(json, false);
}

export function CompanyBasicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyBasicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyShortCode': !exists(json, 'companyShortCode') ? undefined : json['companyShortCode'],
    };
}

export function CompanyBasicInfoToJSON(value?: CompanyBasicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'companyShortCode': value.companyShortCode,
    };
}

