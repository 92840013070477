import { formatNumber } from '../../../services/culture-service'

interface Props {
  currentValue?: number,
  buyValue?: number
}

const GainSpan = (props: Props) => {
  const hasValue = !!props.buyValue && !!props.currentValue

  // todo: wyliczyć gain w oddzielnym komponencie + testy
  const computeGain = (buyValue: number, currentValue: number) => {
    return currentValue - buyValue
  }

  const computeGainPercent = (buyValue: number, currentValue: number) => {
    return (currentValue - buyValue) / buyValue * 100
  }

  const gain = hasValue
    ? computeGain(props.buyValue!, props.currentValue!)
    : undefined

  const gainPercent = hasValue
    ? computeGainPercent(props.buyValue!, props.currentValue!)
    : undefined

  // todo: ładna ikonka i kolor
  return gain !== 0
    ? <span>{formatNumber(gain, 2, '-')} [{formatNumber(gainPercent, 2, '-')} %]</span>
    : <span>{formatNumber(gain, 2, '-')}</span>
}

export default GainSpan
