const AUTH_TOKEN = 'AUTH_TOKEN'

const sessionStorageService = {
  getAuthToken(): string | null {
    return window.sessionStorage.getItem(AUTH_TOKEN)
  },

  saveAuthToken(value: string) {
    window.sessionStorage.setItem(AUTH_TOKEN, value)
  },

  removeAuthToken() {
    window.sessionStorage.removeItem(AUTH_TOKEN)
  }
}

export default sessionStorageService
