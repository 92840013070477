import { ICellRendererParams } from 'ag-grid-community'
import './AgGridCellButton.scss'

interface Props extends ICellRendererParams {
  title: string
  clicked: (row: any) => void
}

// DOCS: https://ag-grid.com/react-data-grid/component-cell-renderer/
const AgGridCellButton = (props: Props) => {
  const buttonClicked = () => {
    props.clicked(props.data)
  }

  return <button className='aggrid-cell-button' onClick={() => buttonClicked()}>{props.title}</button>
}

export default AgGridCellButton
