/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertItem
 */
export interface AlertItem {
    /**
     * 
     * @type {Date}
     * @memberof AlertItem
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    ruleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    message?: string | null;
}

export function AlertItemFromJSON(json: any): AlertItem {
    return AlertItemFromJSONTyped(json, false);
}

export function AlertItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'ruleName': !exists(json, 'ruleName') ? undefined : json['ruleName'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function AlertItemToJSON(value?: AlertItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'ruleName': value.ruleName,
        'message': value.message,
    };
}

