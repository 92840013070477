import React, { useEffect } from 'react'
import styles from './BaseDetailsPage.module.scss'
import classNames from 'classnames'
import BaseDetailsChart from './BaseDetailsPageParts/BaseDetailsChart'
import BaseDetailsTable from './BaseDetailsPageParts/BaseDetailsTable'
import DateRangePredefined from '../../Components/DateRangePredefined/DateRangePredefined'
import { PredefinedDateRangeEnum } from '../../DateRange/PredefinedDateRangeEnum'
import { DateTime } from 'luxon'
import { assetsApiClient } from '../../../../api-client/factory'
import FileSaver from 'file-saver'
import { formatDate } from '../../../../services/culture-service'
import { AssetTypeEnum, QuotesStats } from '../../../../api-client/generated'
import BaseDetailsQuotationsStats from './BaseDetailsPageParts/BaseDetailsQuotationsStats'
import AppLoader from '../../Components/AppLoader/AppLoader'

interface Props {
  items: any[]
  resourceType: AssetTypeEnum
  resourceId: string
  summaryComponent: React.ReactNode
  quotesStats?: QuotesStats // todo: zrobić wymagane
  dateRangeChanged: (startDate?: Date, endDate?: Date) => void
  getValue: (item: any) => number
  isLoadingData?: boolean
}

const BaseDetailsPage = (props: Props) => {
  const fromDate = DateTime.local().plus({ months: -3 }).toJSDate()
  const toDate = new Date()

  const exportToExcel = () => {
    assetsApiClient()
      .apiAssetsResourceTypeResourceIdQuotesExcelGetRaw({
        resourceId: props.resourceId,
        resourceType: props.resourceType,
        from: fromDate,
        to: toDate
      })
      .then((resp) => resp.raw.blob())
      .then((blob) => {
        FileSaver.saveAs(
          blob,
          `${props.resourceId}__${formatDate(fromDate)}_${formatDate(
            toDate
          )}.xlsx`
        )
      })
  }

  const getTrends = () => {
    const result: any[] = []

    if (!!props.quotesStats?.trendWeek?.items) {
      const trendWeek = props.quotesStats.trendWeek

      result.push({
        name: 'trendWeek',
        items: trendWeek.items?.map((p: any) => ({
          dateTime: p.dateTime?.toISOString().split('T')[0],
          value: p.value
        }))
      })
    }

    if (!!props.quotesStats?.trendMonth) {
      const trendMonth = props.quotesStats.trendMonth

      result.push({
        name: 'trendMonth',
        items: trendMonth.items?.map((p) => ({
          dateTime: p.dateTime?.toISOString().split('T')[0],
          value: p.value
        }))
      })
    }

    return result
  }

  useEffect(() => {
    props.dateRangeChanged(fromDate, toDate)
  }, [])

  const renderContent = () => {
    return (
      <div className={styles.baseDetailsGrid}>
        <div className={classNames(styles.box, styles.boxDetails)}>
          {props.summaryComponent}
        </div>

        <div className={classNames(styles.box, styles.boxChart)}>
          <BaseDetailsChart
            items={props.items!}
            getValue={props.getValue}
            trends={getTrends()}
          />
        </div>

        <div className={classNames(styles.box, styles.boxDateChoose)}>
          <DateRangePredefined
            predefinedDateRangeEnum={PredefinedDateRangeEnum.Quarter}
            onChange={props.dateRangeChanged}
            initStartDate={fromDate}
            initEndDate={toDate}
          />
          <hr />
          <div className={styles.statTable}>
            <BaseDetailsQuotationsStats stats={props.quotesStats} />
          </div>
          <hr />
          <div className={styles.actionButtons}>
            <button onClick={exportToExcel}>Export to Excel</button>
          </div>
        </div>

        <div className={classNames(styles.box, styles.boxTable)}>
          <BaseDetailsTable items={props.items!} />
        </div>
      </div>
    )
  }

  return props.isLoadingData ? <AppLoader /> : renderContent()
}

export default BaseDetailsPage
