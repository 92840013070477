import { FC, useEffect } from 'react'
import LoginPage from '../LoginPage/LoginPage'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Layout from '../Layout'
import sessionStorageService from '../../services/session-storage-service'
import { BrowserRouter } from 'react-router-dom'
import AppToastContainer from '../Infrastructure/AppToastContainer'

const App: FC = () => {
  useEffect(() => {
    // Problem with `ResizeObserver loop limit exceeded`
    // DOCS: https://github.com/mui/material-ui/issues/36909
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  const renderContent = () => {
    const authUser = sessionStorageService.getAuthToken()

    return authUser ? <Layout /> : <LoginPage />
  }

  return (
    <div>
      <AppToastContainer />
      <BrowserRouter>{renderContent()}</BrowserRouter>
    </div>
  )
}

export default App
