import { useEffect, useState } from 'react'
import { GetMyAssetsQueryResponse, AssetItem } from '../../../../api-client/generated'
import { TreeTable, TreeState, TreeNode } from 'cp-react-tree-table'
import _ from 'lodash'
import '../DashboardBox.scss'
import './cp-tree-styles.css'
import './DashboardMyAssetsBox.scss'
import { DateTime } from 'luxon'
import { userAssetApiFactory } from '../../../../api-client/factory'
import GainSpan from '../GainSpan'
import { formatNumber } from '../../../../services/culture-service'

const DashboardAssetsBox = () => {
  const [treeValue, setTreeValue] = useState(TreeState.create([]))
  const [quotationDate, setQuotationDate] = useState<string>()
  const [totalValue, setTotalValue] = useState<number>()
  const [totalBuyValue, setTotalBuyValue] = useState<number>()

  useEffect(() => {
    userAssetApiFactory().apiUserAssetsGet()
      .then((resp: GetMyAssetsQueryResponse) => {
        const date = DateTime.fromJSDate(resp.quotationDate!).toFormat('yyyy-MM-dd')
        setQuotationDate(date)

        const totalValueSum = _.sum(resp.assets?.map((p: any) => p.totalValue))
        const totalBuyValueSum = _.sum(resp.assets?.map((p: any) => p.totalBuyValue))

        setTotalValue(totalValueSum)
        setTotalBuyValue(totalBuyValueSum)

        const mapped = mapToTreeView(resp.assets!)
        setTreeValue(mapped)
      })
  }, [])

  const mapToTreeView = (items: AssetItem[]): TreeState<any> => {
    const groupedByType = _.chain(items)
      .groupBy((p: AssetItem) => p.type)
      .map((p) => ({
        items: p
      }))
      .value()

    const result: TreeNode<AssetItem>[] = []
    for (const group of groupedByType) {
      const children = group.items.map(p => ({
        data: p
      }))

      const assetsSum = children
        .map(p => p.data.currentValue! * p.data.count!)
        .reduce((acc, curr) => acc += curr, 0)

      const buyValueSum = children
        .map(p => p.data.buyValue! * p.data.count!)
        .reduce((acc, curr) => acc += curr, 0)

      const data: TreeNode<AssetItem> = {
        data: {
          typeName: group.items[0].typeName,
          type: group.items[0].type,
          name: undefined,
          count: undefined,
          buyValue: undefined,
          totalBuyValue: buyValueSum,
          currentValue: undefined,
          totalValue: assetsSum
        },
        children
      }

      result.push(data)
    }

    return TreeState.create(result)
  }

  const handleOnExpandOrCollapse = (newValue: any) => {
    setTreeValue(newValue)
  }

  const renderTypeCell = (row: any) => {
    return (
      <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px' }}
        className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
        {(row.metadata.hasChildren)
          ? <button className="toggle-button" onClick={row.toggleChildren}></button>
          : ''
        }
        <span>{row.data.typeName}</span>
      </div>
    )
  }

  return <div>
    <span>Notowania z dnia {quotationDate}</span>
    <div className='tree-table'>
      <TreeTable
        value={treeValue}
        onChange={handleOnExpandOrCollapse}>
        <TreeTable.Column basis='12%'
          renderCell={renderTypeCell}
          renderHeaderCell={() => <span>Type</span>} />
        <TreeTable.Column basis='12%'
          renderCell={(row: any) => <span>{row.data.name}</span>}
          renderHeaderCell={() => <span>Name</span>} />
        <TreeTable.Column basis='9%'
          renderCell={(row: any) => <span>{row.data.count}</span>}
          renderHeaderCell={() => <span>Count</span>} />
        <TreeTable.Column basis='10%'
          renderCell={(row: TreeNode<AssetItem>) => <span>{formatNumber(row.data.buyValue)}</span>}
          renderHeaderCell={() => <span>Buy Value</span>} />
        <TreeTable.Column basis='10%'
          renderCell={(row: TreeNode<AssetItem>) => <span>{formatNumber(row.data.currentValue)}</span>}
          renderHeaderCell={() => <span>Value</span>} />
        <TreeTable.Column basis='15%'
          renderCell={(row: TreeNode<AssetItem>) => <span>{formatNumber(row.data.totalValue)}</span>}
          renderHeaderCell={() => <span>Total Value</span>} />
        <TreeTable.Column basis='20%'
          renderCell={(row: any) => <GainSpan buyValue={row.data.totalBuyValue} currentValue={row.data.totalValue} />}
          renderHeaderCell={() => <span>Gain</span>} />
      </TreeTable>
    </div>

    <h6> Total value [PLN] = {formatNumber(totalValue)} </h6>
    <h6> Total gain [PLN] =  <GainSpan buyValue={totalBuyValue} currentValue={totalValue} /></h6>
  </div>
}

export default DashboardAssetsBox
