/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertItem,
    AlertItemFromJSON,
    AlertItemFromJSONTyped,
    AlertItemToJSON,
} from './AlertItem';

/**
 * 
 * @export
 * @interface GetAllAlertsQueryResponse
 */
export interface GetAllAlertsQueryResponse {
    /**
     * 
     * @type {Array<AlertItem>}
     * @memberof GetAllAlertsQueryResponse
     */
    alerts?: Array<AlertItem> | null;
    /**
     * 
     * @type {Date}
     * @memberof GetAllAlertsQueryResponse
     */
    statusDate?: Date | null;
}

export function GetAllAlertsQueryResponseFromJSON(json: any): GetAllAlertsQueryResponse {
    return GetAllAlertsQueryResponseFromJSONTyped(json, false);
}

export function GetAllAlertsQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllAlertsQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alerts': !exists(json, 'alerts') ? undefined : (json['alerts'] === null ? null : (json['alerts'] as Array<any>).map(AlertItemFromJSON)),
        'statusDate': !exists(json, 'statusDate') ? undefined : (json['statusDate'] === null ? null : new Date(json['statusDate'])),
    };
}

export function GetAllAlertsQueryResponseToJSON(value?: GetAllAlertsQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alerts': value.alerts === undefined ? undefined : (value.alerts === null ? null : (value.alerts as Array<any>).map(AlertItemToJSON)),
        'statusDate': value.statusDate === undefined ? undefined : (value.statusDate === null ? null : value.statusDate.toISOString()),
    };
}

