import { CurrencyQuoteItem } from '../../../../api-client/generated'
import {
  addLinkColumn,
  addColumnWithButton
} from '../../../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'
import { mapNamesToAgGridColumns } from '../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import getPropertiesFromObject from '../../../../extensions/getPropertiesFromObject'
import appNavigate from '../../../../services/app-navigation-service'
import StooqUrlNavigationService from '../../../../services/stooq-url-navigation-service'
import UrlNavigationService from '../../../../services/url-service'

export const CurrenciesColumns = (
  firstItem: CurrencyQuoteItem,
  navigate: any
) => {
  const goToStooq = (param: any) => {
    return StooqUrlNavigationService.currencyDetails(param.data.currency)
  }

  const navigateToStockDetails = (row: any) => {
    appNavigate(UrlNavigationService.currencyDetails(row.currency), navigate)
  }

  const names = getPropertiesFromObject(firstItem)
  const cols = mapNamesToAgGridColumns(names)

  const stooqLinkCol = addLinkColumn('stooqLink', 'stooq', goToStooq)
  cols.push(stooqLinkCol)

  const buttonCol = addColumnWithButton('Details', navigateToStockDetails)
  cols.push(buttonCol)

  return cols
}
