/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDividendItem,
    CompanyDividendItemFromJSON,
    CompanyDividendItemFromJSONTyped,
    CompanyDividendItemToJSON,
} from './CompanyDividendItem';

/**
 * 
 * @export
 * @interface GetCompanyDividendDetailsQueryResponse
 */
export interface GetCompanyDividendDetailsQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDividendDetailsQueryResponse
     */
    companyName?: string | null;
    /**
     * 
     * @type {Array<CompanyDividendItem>}
     * @memberof GetCompanyDividendDetailsQueryResponse
     */
    items?: Array<CompanyDividendItem> | null;
}

export function GetCompanyDividendDetailsQueryResponseFromJSON(json: any): GetCompanyDividendDetailsQueryResponse {
    return GetCompanyDividendDetailsQueryResponseFromJSONTyped(json, false);
}

export function GetCompanyDividendDetailsQueryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyDividendDetailsQueryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(CompanyDividendItemFromJSON)),
    };
}

export function GetCompanyDividendDetailsQueryResponseToJSON(value?: GetCompanyDividendDetailsQueryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(CompanyDividendItemToJSON)),
    };
}

