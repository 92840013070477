import { ColDef, ColGroupDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { FC, useCallback, useRef } from 'react'
import formatToIsoDate from '../../../../extensions/PrimitiveExtensions/DateExtensions'
import AgGridCellButton from '../../../../extensions/AgGridExtensions/AgGridCells/AgGridCellButton'
import styles from './BaseListPage.module.scss'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import AppLoader from '../../Components/AppLoader/AppLoader'

interface Props {
  title: string
  statusDate: Date
  rows: any[]
  columnsDef: (ColDef | ColGroupDef)[]
  isLoadingData?: boolean
}

const BaseListPage: FC<Props> = (props: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null)

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.sizeColumnsToFit()
  }, [])

  const renderContent = () => {
    return <div className={styles.baseListPage}>
    <h1>{props.title}</h1>

    <div>State for {formatToIsoDate(props.statusDate)} </div>

    <div
      className="ag-theme-alpine"
      style={{ width: '100%', height: '100%' }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={props.rows}
        columnDefs={props.columnsDef}
        components={{
          agGridCellButton: AgGridCellButton
        }}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  </div>
  }

  return props.isLoadingData ? <AppLoader /> : renderContent()
}

export default BaseListPage
