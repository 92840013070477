import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { currencyApiFactory } from '../../../../api-client/factory'
import { AssetTypeEnum, GetCurrentCurrencyDetailsQueryResponse, QuotesStats } from '../../../../api-client/generated'
import BaseDetailsPage from '../../../Common/Base/BaseDetailsPage/BaseDetailsPage'

const CurrencyDetails = () => {
  const [rowData, setRowData] = useState<any[]>([])
  const [currencyName, setCurrencyName] = useState<string>('')
  const { id } = useParams()
  const [quotesStats, setQuotesStats] = useState<QuotesStats>()

  const dateRangeChanged = (startDate?: Date, endDate?: Date) => {
    currencyApiFactory()
      .apiCurrencyCurrencyShortCodeGet({
        shortCode: id!,
        fromDate: startDate,
        toDate: endDate
      })
      .then((response: GetCurrentCurrencyDetailsQueryResponse) => {
        setRowData(response.items!)
        setCurrencyName(response.currencyName!)

        setQuotesStats(response.queryStats)
      })
  }

  const getIndexValue = (item: any) => {
    return Number(item.buyValue)
  }

  return (
    <BaseDetailsPage
      items={rowData}
      resourceType={AssetTypeEnum.Currency}
      quotesStats={quotesStats}
      resourceId={id!}
      summaryComponent={<h1>{currencyName}</h1>}
      dateRangeChanged={dateRangeChanged}
      getValue={getIndexValue}
    />
  )
}

export default CurrencyDetails
