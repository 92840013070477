export const roundTo = (input: number | string | undefined | null, points: number = 2): string | undefined => {
  if (!input) {
    return undefined
  }

  return parseFloat(input?.toString()).toFixed(points)
}

export const formatTousendsSeparator = (input: number | string | undefined | null): string | undefined => {
  if (!input) {
    return undefined
  }

  return Number(input).toLocaleString('en-US')
    .replaceAll(',', ' ')
}
