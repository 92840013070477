/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDataSourcesQueryResponseClientInfo
 */
export interface GetDataSourcesQueryResponseClientInfo {
    /**
     * 
     * @type {string}
     * @memberof GetDataSourcesQueryResponseClientInfo
     */
    typeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDataSourcesQueryResponseClientInfo
     */
    baseUrl?: string | null;
}

export function GetDataSourcesQueryResponseClientInfoFromJSON(json: any): GetDataSourcesQueryResponseClientInfo {
    return GetDataSourcesQueryResponseClientInfoFromJSONTyped(json, false);
}

export function GetDataSourcesQueryResponseClientInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDataSourcesQueryResponseClientInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'baseUrl': !exists(json, 'baseUrl') ? undefined : json['baseUrl'],
    };
}

export function GetDataSourcesQueryResponseClientInfoToJSON(value?: GetDataSourcesQueryResponseClientInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typeName': value.typeName,
        'baseUrl': value.baseUrl,
    };
}

