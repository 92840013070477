import { ICellRendererParams } from 'ag-grid-community'
import { TimestampedValue } from '../../../api-client/generated'
import { formatDate } from '../../../services/culture-service'

interface Props extends ICellRendererParams {
  getDataValue: (params: any) => TimestampedValue
}

const AgGridCellTimestampedValue = (props: Props) => {
  const data = props.getDataValue(props)

  if (!data) {
    return <></>
  }

  return (
    <>
      <div>{data?.value}</div>
      <div>{formatDate(data?.timeStamp)}</div>
    </>
  )
}

export default AgGridCellTimestampedValue
