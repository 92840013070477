import { StockMarketIndexList } from '../../../../api-client/generated'
import {
  addDiffValueAndPercent,
  addLinkColumn,
  addColumnWithButton
} from '../../../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'
import { mapNamesToAgGridColumns } from '../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import getPropertiesFromObject from '../../../../extensions/getPropertiesFromObject'
import appNavigate from '../../../../services/app-navigation-service'
import StooqUrlNavigationService from '../../../../services/stooq-url-navigation-service'
import UrlNavigationService from '../../../../services/url-service'

export const MarketIndexesColumns = (
  firstItem: StockMarketIndexList,
  navigate: any
) => {
  const goToStooq = (param: any) => {
    return StooqUrlNavigationService.stockMarketIndexDetails(param.data.name)
  }

  const navigateToStockDetails = (row: any) => {
    appNavigate(UrlNavigationService.marketIndexDetails(row.name), navigate)
  }

  const names = getPropertiesFromObject(firstItem)
  const cols = mapNamesToAgGridColumns(names)

  const gainCol = addDiffValueAndPercent('Gain [1d]')
  cols.push(gainCol)

  const stooqLinkCol = addLinkColumn('stooqLink', 'stooq', goToStooq)
  cols.push(stooqLinkCol)

  const buttonCol = addColumnWithButton('Details', navigateToStockDetails)
  cols.push(buttonCol)

  const colsDef = cols.filter(
    (p) => p.field !== 'prevValues' && p.field !== 'close'
  )
  return colsDef
}
