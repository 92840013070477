/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import {
    DateTimeQuarterEnum,
    DateTimeQuarterEnumFromJSON,
    DateTimeQuarterEnumFromJSONTyped,
    DateTimeQuarterEnumToJSON,
} from './DateTimeQuarterEnum';

/**
 * 
 * @export
 * @interface CompanyIndicator
 */
export interface CompanyIndicator {
    /**
     * 
     * @type {number}
     * @memberof CompanyIndicator
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyIndicator
     */
    created?: Date;
    /**
     * 
     * @type {number}
     * @memberof CompanyIndicator
     */
    companyId?: number;
    /**
     * 
     * @type {Company}
     * @memberof CompanyIndicator
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof CompanyIndicator
     */
    year?: number;
    /**
     * 
     * @type {DateTimeQuarterEnum}
     * @memberof CompanyIndicator
     */
    quarter?: DateTimeQuarterEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanyIndicator
     */
    priceEarningRating?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyIndicator
     */
    priceBookValue?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyIndicator
     */
    rating?: string | null;
}

export function CompanyIndicatorFromJSON(json: any): CompanyIndicator {
    return CompanyIndicatorFromJSONTyped(json, false);
}

export function CompanyIndicatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyIndicator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'year': !exists(json, 'year') ? undefined : json['year'],
        'quarter': !exists(json, 'quarter') ? undefined : DateTimeQuarterEnumFromJSON(json['quarter']),
        'priceEarningRating': !exists(json, 'priceEarningRating') ? undefined : json['priceEarningRating'],
        'priceBookValue': !exists(json, 'priceBookValue') ? undefined : json['priceBookValue'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
    };
}

export function CompanyIndicatorToJSON(value?: CompanyIndicator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'companyId': value.companyId,
        'company': CompanyToJSON(value.company),
        'year': value.year,
        'quarter': DateTimeQuarterEnumToJSON(value.quarter),
        'priceEarningRating': value.priceEarningRating,
        'priceBookValue': value.priceBookValue,
        'rating': value.rating,
    };
}

