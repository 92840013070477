/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetTypeEnum,
    AssetTypeEnumFromJSON,
    AssetTypeEnumFromJSONTyped,
    AssetTypeEnumToJSON,
} from './AssetTypeEnum';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import {
    UserAssetBaseEntity,
    UserAssetBaseEntityFromJSON,
    UserAssetBaseEntityFromJSONTyped,
    UserAssetBaseEntityToJSON,
} from './UserAssetBaseEntity';

/**
 * 
 * @export
 * @interface UserAssetsStackEntity
 */
export interface UserAssetsStackEntity {
    /**
     * 
     * @type {AssetTypeEnum}
     * @memberof UserAssetsStackEntity
     */
    type?: AssetTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsStackEntity
     */
    companyId?: number;
    /**
     * 
     * @type {Company}
     * @memberof UserAssetsStackEntity
     */
    company?: Company;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsStackEntity
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsStackEntity
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsStackEntity
     */
    buyPrice?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserAssetsStackEntity
     */
    buyDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserAssetsStackEntity
     */
    ownerId?: number;
}

export function UserAssetsStackEntityFromJSON(json: any): UserAssetsStackEntity {
    return UserAssetsStackEntityFromJSONTyped(json, false);
}

export function UserAssetsStackEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAssetsStackEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : AssetTypeEnumFromJSON(json['type']),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'buyPrice': !exists(json, 'buyPrice') ? undefined : json['buyPrice'],
        'buyDate': !exists(json, 'buyDate') ? undefined : (json['buyDate'] === null ? null : new Date(json['buyDate'])),
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
    };
}

export function UserAssetsStackEntityToJSON(value?: UserAssetsStackEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AssetTypeEnumToJSON(value.type),
        'companyId': value.companyId,
        'company': CompanyToJSON(value.company),
        'id': value.id,
        'count': value.count,
        'buyPrice': value.buyPrice,
        'buyDate': value.buyDate === undefined ? undefined : (value.buyDate === null ? null : value.buyDate.toISOString()),
        'ownerId': value.ownerId,
    };
}

