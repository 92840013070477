/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdNamePair,
    IdNamePairFromJSON,
    IdNamePairFromJSONTyped,
    IdNamePairToJSON,
} from './IdNamePair';

/**
 * 
 * @export
 * @interface IdNamePairHierarchy
 */
export interface IdNamePairHierarchy {
    /**
     * 
     * @type {IdNamePair}
     * @memberof IdNamePairHierarchy
     */
    key?: IdNamePair;
    /**
     * 
     * @type {Array<IdNamePair>}
     * @memberof IdNamePairHierarchy
     */
    items?: Array<IdNamePair> | null;
}

export function IdNamePairHierarchyFromJSON(json: any): IdNamePairHierarchy {
    return IdNamePairHierarchyFromJSONTyped(json, false);
}

export function IdNamePairHierarchyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdNamePairHierarchy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : IdNamePairFromJSON(json['key']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(IdNamePairFromJSON)),
    };
}

export function IdNamePairHierarchyToJSON(value?: IdNamePairHierarchy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': IdNamePairToJSON(value.key),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(IdNamePairToJSON)),
    };
}

