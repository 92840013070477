/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyNameId,
    CompanyNameIdFromJSON,
    CompanyNameIdToJSON,
    GetAllCompaniesDividendsQueryResponse,
    GetAllCompaniesDividendsQueryResponseFromJSON,
    GetAllCompaniesDividendsQueryResponseToJSON,
    GetAllCompaniesQueryResponse,
    GetAllCompaniesQueryResponseFromJSON,
    GetAllCompaniesQueryResponseToJSON,
    GetCompanyDetailsResponse,
    GetCompanyDetailsResponseFromJSON,
    GetCompanyDetailsResponseToJSON,
    GetCompanyDividendDetailsQueryResponse,
    GetCompanyDividendDetailsQueryResponseFromJSON,
    GetCompanyDividendDetailsQueryResponseToJSON,
    GetStockRecommendationsResponse,
    GetStockRecommendationsResponseFromJSON,
    GetStockRecommendationsResponseToJSON,
} from '../models';

export interface ApiStockCompanyIdDividendGetRequest {
    companyId: number;
}

export interface ApiStockCompanyIdGetRequest {
    companyId: number;
    from?: Date;
    to?: Date;
}

/**
 * 
 */
export class StockApi extends runtime.BaseAPI {

    /**
     */
    async apiStockAllDividendGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllCompaniesDividendsQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Stock/all/dividend`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllCompaniesDividendsQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiStockAllDividendGet(initOverrides?: RequestInit): Promise<GetAllCompaniesDividendsQueryResponse> {
        const response = await this.apiStockAllDividendGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockAllGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAllCompaniesQueryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Stock/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllCompaniesQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiStockAllGet(initOverrides?: RequestInit): Promise<GetAllCompaniesQueryResponse> {
        const response = await this.apiStockAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockCompaniesWithIdGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CompanyNameId>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Stock/companies-with-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyNameIdFromJSON));
    }

    /**
     */
    async apiStockCompaniesWithIdGet(initOverrides?: RequestInit): Promise<Array<CompanyNameId>> {
        const response = await this.apiStockCompaniesWithIdGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockCompanyIdDividendGetRaw(requestParameters: ApiStockCompanyIdDividendGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCompanyDividendDetailsQueryResponse>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiStockCompanyIdDividendGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Stock/{companyId}/dividend`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyDividendDetailsQueryResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiStockCompanyIdDividendGet(requestParameters: ApiStockCompanyIdDividendGetRequest, initOverrides?: RequestInit): Promise<GetCompanyDividendDetailsQueryResponse> {
        const response = await this.apiStockCompanyIdDividendGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockCompanyIdGetRaw(requestParameters: ApiStockCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCompanyDetailsResponse>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiStockCompanyIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Stock/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiStockCompanyIdGet(requestParameters: ApiStockCompanyIdGetRequest, initOverrides?: RequestInit): Promise<GetCompanyDetailsResponse> {
        const response = await this.apiStockCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStockRecommendationsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetStockRecommendationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Stock/recommendations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStockRecommendationsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiStockRecommendationsGet(initOverrides?: RequestInit): Promise<GetStockRecommendationsResponse> {
        const response = await this.apiStockRecommendationsGetRaw(initOverrides);
        return await response.value();
    }

}
