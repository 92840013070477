import { Route, Routes } from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard'
import LoginPage from '../LoginPage/LoginPage'
import Currencies from './Currencies/List/Currencies'
import MarketIndexDetails from './MarketIndexes/Details/MarketIndexDetails'
import MarketIndexes from './MarketIndexes/List/MarketIndexes'
import StockDetails from './Stocks/Details/StockDetails'
import Stocks from './Stocks/List/Stocks'
import AlertRules from '../AlertRules/AlertRules'
import AddAlertRuleForm from '../AlertRules/AddAlertRuleForm'
import AlertsList from '../Alerts/AlertsList'
import CurrencyDetails from './Currencies/Details/CurrencyDetails'
import AlertTypes from '../AlertRules/AlertTypes'
import MarketIndexDetailsComponents from './MarketIndexes/Details/MarketIndexDetailsComponents'
import StockDetailsDividend from './Stocks/Details/StockDetailsDividend'
import StocksAllDividendList from './Stocks/DividendList/StocksAllDividendList'
import ImportStatuses from '../Settings/ImportStatuses'
import Basket from '../Basket/Basket'
import TradeStockCalculations from '../Analyzes/Calculations/TradeStockCalculations'
import DataSources from '../Settings/DataSources'
import StocksRecommendationsList from './Stocks/Recommendations/StocksRecommendationsList'
import MultiChart from '../Analyzes/MultiChart/MultiChart'
import NobleMetalsDetails from './NobleMetals/NobleMetalsDetails'
import Assistant from './Assistant/Assistant'

const AppRouting = () => {
  return (<Routes>
    <Route path="login" element={<LoginPage />} />
    <Route path="market-indexes" element={<MarketIndexes />} />
    <Route path="market-indexes/:name" element={<MarketIndexDetails />} />
    <Route path="market-indexes/:name/components" element={<MarketIndexDetailsComponents />} />
    <Route path="stocks" element={<Stocks />} />
    <Route path="stocks/all-dividend" element={<StocksAllDividendList />} />
    <Route path="stocks/recommendations" element={<StocksRecommendationsList />} />
    <Route path="stocks/:id" element={<StockDetails />} />
    <Route path="stocks/:id/dividend" element={<StockDetailsDividend />} />
    <Route path="currencies" element={<Currencies />} />
    <Route path="currencies/:id" element={<CurrencyDetails />} />
    <Route path="noble-metals/:id" element={<NobleMetalsDetails />} />
    <Route path="basket" element={<Basket />} />
    <Route path="calculations" element={<TradeStockCalculations />} />
    <Route path="multi-chart" element={<MultiChart />} />
    <Route path="alerts" element={<AlertsList />} />
    <Route path="alert-rules" element={<AlertRules />} />
    <Route path="alert-rules/add" element={<AddAlertRuleForm />} />
    <Route path="alert-types" element={<AlertTypes />} />
    <Route path="import-statuses" element={<ImportStatuses />} />
    <Route path="data-sources" element={<DataSources />} />
    <Route path="dashboard" element={<Dashboard />} />
    <Route path="assistant" element={<Assistant />} />
    <Route path="*" element={<Dashboard />} />
  </Routes>)
}

export default AppRouting
