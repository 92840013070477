import { ICellRendererParams } from 'ag-grid-community'
import GainSpan from '../../../components/Dashboard/boxes/GainSpan'

interface Props extends ICellRendererParams {
  getCurrentValue: (props: any) => number
  getPrevValue: (props: any) => number
}

const AgGridCellDiff = (props: Props) => {
  return (
    <GainSpan buyValue={props.getPrevValue(props)} currentValue={props.getCurrentValue(props)} />
  )
}

export default AgGridCellDiff
