/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValueDayAgo,
    ValueDayAgoFromJSON,
    ValueDayAgoFromJSONTyped,
    ValueDayAgoToJSON,
} from './ValueDayAgo';

/**
 * 
 * @export
 * @interface StockMarketIndexList
 */
export interface StockMarketIndexList {
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockMarketIndexList
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    open?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    close?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    currentValue?: number;
    /**
     * 
     * @type {Array<ValueDayAgo>}
     * @memberof StockMarketIndexList
     */
    prevValues?: Array<ValueDayAgo> | null;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    trendWeek?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexList
     */
    trendMonth?: number | null;
}

export function StockMarketIndexListFromJSON(json: any): StockMarketIndexList {
    return StockMarketIndexListFromJSONTyped(json, false);
}

export function StockMarketIndexListFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMarketIndexList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'open': !exists(json, 'open') ? undefined : json['open'],
        'close': !exists(json, 'close') ? undefined : json['close'],
        'currentValue': !exists(json, 'currentValue') ? undefined : json['currentValue'],
        'prevValues': !exists(json, 'prevValues') ? undefined : (json['prevValues'] === null ? null : (json['prevValues'] as Array<any>).map(ValueDayAgoFromJSON)),
        'trendWeek': !exists(json, 'trendWeek') ? undefined : json['trendWeek'],
        'trendMonth': !exists(json, 'trendMonth') ? undefined : json['trendMonth'],
    };
}

export function StockMarketIndexListToJSON(value?: StockMarketIndexList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'count': value.count,
        'min': value.min,
        'max': value.max,
        'open': value.open,
        'close': value.close,
        'currentValue': value.currentValue,
        'prevValues': value.prevValues === undefined ? undefined : (value.prevValues === null ? null : (value.prevValues as Array<any>).map(ValueDayAgoToJSON)),
        'trendWeek': value.trendWeek,
        'trendMonth': value.trendMonth,
    };
}

