/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StockMarketIndexQuoteEntity,
    StockMarketIndexQuoteEntityFromJSON,
    StockMarketIndexQuoteEntityFromJSONTyped,
    StockMarketIndexQuoteEntityToJSON,
} from './StockMarketIndexQuoteEntity';

/**
 * 
 * @export
 * @interface StockMarketIndexEntity
 */
export interface StockMarketIndexEntity {
    /**
     * 
     * @type {number}
     * @memberof StockMarketIndexEntity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StockMarketIndexEntity
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StockMarketIndexEntity
     */
    firstQuotationDate?: Date | null;
    /**
     * 
     * @type {Array<StockMarketIndexQuoteEntity>}
     * @memberof StockMarketIndexEntity
     */
    quotes?: Array<StockMarketIndexQuoteEntity> | null;
    /**
     * 
     * @type {Array<StockMarketIndexEntity>}
     * @memberof StockMarketIndexEntity
     */
    companies?: Array<StockMarketIndexEntity> | null;
}

export function StockMarketIndexEntityFromJSON(json: any): StockMarketIndexEntity {
    return StockMarketIndexEntityFromJSONTyped(json, false);
}

export function StockMarketIndexEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMarketIndexEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstQuotationDate': !exists(json, 'firstQuotationDate') ? undefined : (json['firstQuotationDate'] === null ? null : new Date(json['firstQuotationDate'])),
        'quotes': !exists(json, 'quotes') ? undefined : (json['quotes'] === null ? null : (json['quotes'] as Array<any>).map(StockMarketIndexQuoteEntityFromJSON)),
        'companies': !exists(json, 'companies') ? undefined : (json['companies'] === null ? null : (json['companies'] as Array<any>).map(StockMarketIndexEntityFromJSON)),
    };
}

export function StockMarketIndexEntityToJSON(value?: StockMarketIndexEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'firstQuotationDate': value.firstQuotationDate === undefined ? undefined : (value.firstQuotationDate === null ? null : value.firstQuotationDate.toISOString()),
        'quotes': value.quotes === undefined ? undefined : (value.quotes === null ? null : (value.quotes as Array<any>).map(StockMarketIndexQuoteEntityToJSON)),
        'companies': value.companies === undefined ? undefined : (value.companies === null ? null : (value.companies as Array<any>).map(StockMarketIndexEntityToJSON)),
    };
}

