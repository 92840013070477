import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

interface Props {
  isModalShown: boolean
  onAdd: (value: any) => void
  onClose: () => void
}

const AddMarketIndexesModal = (props: Props) => {
  const [indexName, setIndexName] = useState<string>('')

  const closeModal = () => {
    props.onClose()
  }

  const customStyles = {
    margin: '1rem'
  }

  return (
    <Modal
      show={props.isModalShown}
      onRequestClose={closeModal}
      style={customStyles}
      size={'lg'}
    >
      <Modal.Header>
        <h2>Add Market Index</h2>
      </Modal.Header>
      <Modal.Body>
        Add index{' '}
        <input
          onChange={(e) => setIndexName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Cancel</Button>
        <Button onClick={() => props.onAdd(indexName)}>Add</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddMarketIndexesModal
