import BaseListPage from '../Common/Base/BaseListPage/BaseListPage'
import { useEffect, useState } from 'react'
import { alertApiFactory } from '../../api-client/factory'
import { GetAllAlertsQueryResponse } from '../../api-client/generated'
import { mapNamesToAgGridColumns } from '../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'
import getPropertiesFromObject from '../../extensions/getPropertiesFromObject'

const AlertsList = () => {
  const [rowData, setRowData] = useState<any[]>([])
  const [columns, setColumns] = useState<any[]>([])
  const [statusDate, setStatusDate] = useState<Date>(new Date())

  useEffect(() => {
    alertApiFactory()
      .apiAlertAllGet()
      .then((response: GetAllAlertsQueryResponse) => {
        const names = getPropertiesFromObject(response.alerts![0]!)
        const cols = mapNamesToAgGridColumns(names)

        setColumns(cols)
        setRowData(response.alerts!)

        setStatusDate(response.statusDate!)
      })
  }, [])

  return (
    <>
      <div>
        <BaseListPage
          columnsDef={columns}
          rows={rowData}
          statusDate={statusDate}
          title="Alerts"
        />
      </div>
    </>
  )
}

export default AlertsList
