import { useCallback, useEffect, useRef, useState } from 'react'
import { alertApiFactory } from '../../../../api-client/factory'
import { AgGridReact } from 'ag-grid-react'
import styles from './DashboardAlertsBox.module.scss'
import classNames from 'classnames'
import { mapNamesToAgGridColumns } from '../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'

const DashboardMyAssetsBox = () => {
  const [rows, setRows] = useState<any[]>([])

  const gridRef = useRef<AgGridReact<any>>(null)

  useEffect(() => {
    alertApiFactory()
      .apiAlertAllGet()
      .then((response) => {
        setRows(response!.alerts!)
      })
  }, [])

  const onFirstDataRendered = useCallback(() => {
    const allColumnIds: any[] = []
    gridRef!.current!.columnApi!.getColumns()!.forEach((column) => {
      allColumnIds.push(column.getId())
    })

    gridRef!.current!.columnApi!.autoSizeColumns(allColumnIds, true)
  }, [])

  const colDef = mapNamesToAgGridColumns([
    { name: 'date', type: 'date' },
    { name: 'message', type: 'text' }
  ])

  return (
    <div className={classNames('ag-theme-alpine', styles.dashboardAlertsBox)}>
      <AgGridReact
        ref={gridRef}
        columnDefs={colDef}
        rowData={rows}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default DashboardMyAssetsBox
