import { useEffect, useState } from 'react'
import BaseListPage from '../../../Common/Base/BaseListPage/BaseListPage'
import { GetStockRecommendationsResponseItem } from '../../../../api-client/generated'
import { ColDef } from 'ag-grid-community'
import { stockApiFactory } from '../../../../api-client/factory'
import getPropertiesFromObject from '../../../../extensions/getPropertiesFromObject'
import { mapNamesToAgGridColumns } from '../../../../extensions/AgGridExtensions/AgGridColumns/ColumnsExtensions'

const StocksRecommendationsList = () => {
  useEffect(() => {
    stockApiFactory().apiStockRecommendationsGet().then((response) => {
      const firstItem = response.items![0]

      const names = getPropertiesFromObject(firstItem)
      const cols = mapNamesToAgGridColumns(names, ['companyId', 'actionId'])

      setColumns(cols)

      setRows(response.items!)
    })
  }, [])

  const [rows, setRows] = useState<GetStockRecommendationsResponseItem[]>([])
  const [columns, setColumns] = useState<ColDef[]>([])

  // todo: return
  return <BaseListPage
    title="Stock recommendations"
    statusDate={new Date()}
    columnsDef={columns}
    rows={rows}
  />
}

export default StocksRecommendationsList
